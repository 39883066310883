import { Navigate, useLocation } from "react-router-dom";
const Protected = ({children}) => {
    const isLoggedIn = localStorage.getItem('token');
    const location = useLocation()
 if (!isLoggedIn) {
    if(location.pathname !== '/login') {
        return <Navigate to="/login" replace />;
    } else {
        return children;
    }
 } else {
    if(location.pathname === '/login') {
        return <Navigate to="/" replace />;
    } else {
        return children;
    }
 }
};
export default Protected;