import { Button, Col, Form, Image, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import Sidebar from '../../Component/Sidebar/Sidebar';
import MainHeader from '../../Component/MainHeader/MainHeader';
import './WithDrawPage.css';
import { toast } from 'react-toastify';

function WithDrawPage() {
const [toggleMenu, setToggleMenu] = useState(false)
const [amount, setamount] = useState('');
const navigate = useNavigate();

const handleClick = () =>{
  setToggleMenu(!toggleMenu)
}
const handleButtonClick = (value) => {
  setamount(value);
};

const onHandleChange = (event) => {
  const value = event.target.value; 
  setamount(value);
};
 const handleUPI = (e) =>{
  e.preventDefault()
  if(!amount){
    toast.error("Please Enter an Amount")
  }else{
      navigate(`/upiwithdraw/${amount}`,{state:{amount: amount}});
  }
 }
 const handleBank = (e) =>{
  e.preventDefault()
  if(!amount){
    toast.error("Please Enter an Amount")
  }else{
      navigate(`/bankwithdraw/${amount}`,{state:{amount: amount}});
  }
 }

  return (
    <>
      <div className={`MainScreen  ${toggleMenu ? 'show' : ''}`}>
        <MainHeader handleClick={handleClick} />
        <hr/>
        <Sidebar />
        <div className='AddCashBody'>
          <h4>Withdraw</h4>
          <Form>
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <Form.Label>Enter Amount</Form.Label>
              <Form.Control type="number" placeholder="Enter ₹"  value={amount}
                onChange={onHandleChange}/>
            </Form.Group>
            <Row>
              <Col lg={6} md={6} sm={6} xs={6}>
                <label className="customPriceBox" htmlFor="Price01">
                  <input id="Price01" type="radio" name='PriceRadio' onClick={() => handleButtonClick('100')}/>
                  <p className='btn-white'>₹100</p>
                </label>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6}>
                <label className="customPriceBox" htmlFor="Price02">
                  <input id="Price02" type="radio" name='PriceRadio' onClick={() => handleButtonClick('250')}/>
                  <p className='btn-white'>₹250</p>
                </label>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6}>
                <label className="customPriceBox" htmlFor="Price03">
                  <input id="Price03" type="radio" name='PriceRadio' onClick={() => handleButtonClick('500')}/>
                  <p className='btn-white'>₹500</p>
                </label>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6}>
                <label className="customPriceBox" htmlFor="Price04">
                  <input id="Price04" type="radio" name='PriceRadio' onClick={() => handleButtonClick('1000')}/>
                  <p className='btn-white'>₹1000</p>
                </label>
              </Col>
            </Row>
          </Form>
          <hr/>
          <div className='ChooseBank'>
           <Link onClick={handleUPI} >
              <span><Image src='/image/UIPIcon.svg' /></span>
              <h6>Withdraw to UPI <small className='text-danger'>Minimum withdraw amount</small></h6>
            </Link>
            <Link  onClick={handleBank}>
              <span><Icon icon="mdi:bank" /></span>
              <h6>Bank Transfer <small className='text-danger'>Minimum withdraw amount</small></h6>
            </Link>
          </div>
          <hr/>
          {/* <Link to="/" className='btn btn-green d-block w-100 text-center mt-5'>NEXT</Link> */}
        </div>
      </div> 
    </>
  );
}

export default WithDrawPage;
