import Axios from "../../AxiosApi";

export const onReferHistory = (referData) => {
    return Axios({
      method: "post",
      url: `referral/transactions`,
      data: referData
    })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
  };

  export const getReferralview = () => {
    return Axios({
      method: "get",
      url: `referral/view`,
    })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
  };