import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./Pages/LoginPage/LoginPage";
import SupportPage from "./Pages/SupportPage/SupportPage";
import NotificationPage from "./Pages/NotificationPage/NotificationPage";
import ReferEarnPage from "./Pages/ReferEarnPage/ReferEarnPage";
import TransactionsPage from "./Pages/TransactionsPage/TransactionsPage";
import GameHistory from "./Pages/GameHistory/GameHistory";
import WalletPage from "./Pages/WalletPage/WalletPage";
import AddCashPage from "./Pages/AddCashPage/AddCashPage";
import ProfilePage from "./Pages/ProfilePage/ProfilePage";
import EarningPage from "./Pages/EarningPage/EarningPage";
import PanCardPage from "./Pages/PanCardPage/PanCardPage";
import DashBoardPage from "./Pages/DashBoardPage/DashBoardPage";
import KycPage from "./Pages/KycPage/KycPage";
import KycValidation from "./Pages/KycValidation/KycValidation";
import GameList from "./Pages/GameList/GameList";
import MainBanner from "./Component/MainBanner/MainBanner";
import TermsCondition from "./Pages/TermsCondition/TermsCondition";
import PrivacyPolicy from "./Pages/TermsCondition/PrivacyPolicy";
import RefundPolicy from "./Pages/TermsCondition/RefundPolicy";
import ResponsibleGaming from "./Pages/TermsCondition/ResponsibleGaming";
import RoomCode from "./Pages/RoomCode/RoomCode";
import WithDrawPage from "./Pages/WithDrawPage/WithDrawPage";
import UpiWithDraw from "./Pages/UpiWithDraw/UpiWithDraw";
import BankWithDraw from "./Pages/BankWithDraw/BankWithDraw";
import LegalTerm from "./Pages/LegalTerm/LegalTerm";
import "./App.css";
import Protected from "./utils/protected";
import Rules from "./Pages/TermsCondition/Rules";
import AadharKYC from "./Pages/KycPage/AadharKYC";
import ReferralHistory from "./Pages/ReferralHistory/ReferralHistory";
import { useEffect } from "react";
import ReferCode from "./utils/referCode";
import PaymentMethods from "./Pages/AddCashPage/PaymentMethods";
import WithdrawRequest from "./Pages/WithdrawRequest/WithrawRequest";

function App() {
  useEffect(() => {
    return () => {
      document.addEventListener('contextmenu', handelRightClick);
    };
  }, []);
  const handelRightClick = (event) => {
    event.preventDefault();
  }
  document.addEventListener('contextmenu', handelRightClick);
  return (
    <BrowserRouter>
      {/* <Header /> */}
      <Routes>
        {/* <Route path="/" element={<HomePage />} /> */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/refer/:referralCode" element={<ReferCode />} />
        <Route path="/" element={<DashBoardPage />} />
        <Route path="/support" element={<Protected><SupportPage /></Protected>} />
        <Route path="/notification" element={<Protected><NotificationPage /></Protected>} />
        <Route path="/refer-earn" element={<Protected><ReferEarnPage /></Protected>} />
        <Route path="/transactions" element={<Protected><TransactionsPage /></Protected>} />
        <Route path="/game-history" element={<Protected><GameHistory /></Protected>} />
        <Route path="/wallet" element={<Protected><WalletPage /></Protected>} />
        <Route path="/addcash" element={<Protected><AddCashPage /></Protected>} />
        <Route path="/profile" element={<Protected><ProfilePage /></Protected>} />
        <Route path="/earning" element={<Protected><EarningPage /></Protected>} />
        <Route path="/Kyc" element={<Protected><KycPage /></Protected>} />
        <Route path="/kyc-validation" element={<Protected><KycValidation /></Protected>} />
        <Route path="/gamelist/:gameType" element={<Protected><GameList /></Protected>} />
        <Route path="/TermsCondition" element={<Protected><TermsCondition /></Protected>} />
        <Route path="/PrivacyPolicy" element={<Protected><PrivacyPolicy /></Protected>} />
        <Route path="/RefundPolicy" element={<Protected><RefundPolicy /></Protected>} />
        <Route path="/ResponsibleGaming" element={<Protected><ResponsibleGaming /></Protected>} />
        <Route path="/roomcode" element={<Protected><RoomCode /></Protected>} />
        <Route path="/withdraw" element={<Protected><WithDrawPage /></Protected>} />
        <Route path="/upiwithdraw/:amount" element={<Protected><UpiWithDraw /></Protected>} />
        <Route path="/bankwithdraw/:amount" element={<Protected><BankWithDraw /></Protected>} />
        <Route path="/pancard" element={<Protected><PanCardPage /></Protected>} />
        <Route path="/withraw-request" element={<Protected><WithdrawRequest /></Protected>} />
        <Route path="/LegalTerm" element={<Protected><LegalTerm /></Protected>} />
        <Route path="/paymentmethods/:amount" element={<Protected><PaymentMethods /></Protected>} />
        <Route path="/Rules" element={<Protected><Rules /></Protected>} />
        <Route path="/AadharKYC" element={<Protected><AadharKYC /></Protected>} />
        <Route path="/referral-history" element={<Protected><ReferralHistory /></Protected>} />
      </Routes>
      {/* <Footer /> */}
      <MainBanner />
    </BrowserRouter>
  );
}

export default App;
