import { Button, Image } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import Sidebar from '../../Component/Sidebar/Sidebar';
import MainHeader from '../../Component/MainHeader/MainHeader';
import './SupportPage.css';
import { useEffect } from 'react';

function SupportPage() {
  const [toggleMenu, setToggleMenu] = useState(false)
  const handleClick = () => {
    setToggleMenu(!toggleMenu)
  }

  const handleChat = () => {
    const script = document.createElement('script');
    script.src = '//code.tidio.co/v4oqp2a9ttw0xc8cwr1nv4bda9k0v2mm.js';
    // script.setAttribute("chat", "true");
    script.async = true;
    document.body.appendChild(script);
  }
  return (
    <>
      <div className={`MainScreen  ${toggleMenu ? 'show' : ''}`}>
        <MainHeader handleClick={handleClick} />
        <hr />
        <Sidebar />
        <div className='SupportBody'>
          <Image src='/image/ContactUs.png' />
          <h6>Contact Us at below platforms. </h6>
          <div className='supportChat'>
            <Button className='btn btn-green d-block w-100 text-center' onClick={handleChat}><Icon icon="material-symbols:chat" className="m-1" />Live Chat</Button>
            <a className='btn btn-instagram d-block w-100 text-center my-3' href='https://www.instagram.com/rkludo_/' ><Icon icon="mdi:instagram" className="m-1" />Connect On Instagram</a>
            <a className='btn btn-outline-green d-block w-100 text-center' href='mailto:info@rkludo.com' ><Icon icon="mdi:email" className="m-1" />Reach Us Via Email</a>
          </div>
          {/*<div className='BtnWrap mt-3'>
            <a href='' className='btn Btn-White'><Icon icon="material-symbols:phone-in-talk" /> +918690049408</a>
            <a href='mailto:info@rkludo.com' className='btn Btn-White'><Icon icon="mdi:email" /> Email</a>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default SupportPage;
