import Axios from "../../AxiosApi";

export const onWithdrawRequest = (transactionData) => {
    return Axios({
      method: "post",
      url: `transactions/get-withdrawal-request`,
      data: transactionData
    })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
  };
  