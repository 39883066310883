import { Button, Col, Form, Image, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import './MainHeader.css';
import { onGetUserProfile } from '../../Pages/ProfilePage/action';
import { useEffect, useState } from 'react';

function MainHeader({ handleClick }) {
  const [userInfo, setUserInfo] = useState({});
  const isLoggedIn = localStorage.getItem('token');
  const navigate = useNavigate();
  useEffect(() => {
    getUserProfile();
    // console.log(isLoggedIn)
  }, [0]);

  const getUserProfile = () => {
    onGetUserProfile().then((response) => {
      // console.log({ response })
      if (response?.data?.IsSuccess) {
        setUserInfo(response.data);
      } else {
        navigate('/')
      }
    });
  };


  return (
    <>
      <div className='MainScreenHeader'>
        {isLoggedIn && <Link onClick={handleClick} className='menuIcon' ><Icon className='MenuIconMenu' icon="material-symbols:menu-rounded" /> <Icon className='closeMenu' icon="ic:outline-close" /></Link>}
        <Link className='LogoIcon' to="/"><Image src="/image/LogoIcon.png" /></Link>
        {isLoggedIn ?
          (<div className='moneyWrap '>
            <Link className='AddMoney ' to="/addcash">
              <span><Icon icon="fluent-emoji:money-with-wings" /></span>
              <h6>
                <small>CASH</small>
                {userInfo?.wallet_balance}
              </h6>
              <i><Icon icon="material-symbols:add-box" /></i>
            </Link>
            <Link className='AddMoney EarnMoney' to="/earning">
              <span><Icon icon="noto-v1:money-bag" /></span>
              <h6>
                <small>EARNING</small>
                {userInfo.referral_balance}
              </h6>
            </Link>
          </div>)
          : (
            <div className='HeaderLoginWrap'>
              <Link className='btn loginbtn' to="/login">SIGNUP</Link>
              <Link className='btn loginbtn text-white' to="/login">LOGIN</Link>
            </div>)
        }
      </div>
    </>
  );
}

export default MainHeader;
