import { Button, Form } from 'react-bootstrap';
import { useState } from 'react';
import Sidebar from '../../Component/Sidebar/Sidebar';
import MainHeader from '../../Component/MainHeader/MainHeader';
import './PanCardPage.css';
import { onStorePancard } from '../EarningPage/action';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function PanCardPage() {
  const [toggleMenu, setToggleMenu] = useState(false)
  const [pancardData, setpancardData] = useState({
    name: "",
    pan_card_number: "",
    birth_date: "",
    photo:""
  });
  const navigate = useNavigate();
  const handleClick = () => {
    setToggleMenu(!toggleMenu)
  }
  function onHandleChange({ target }) {
    const { name, value } = target;
    
    setpancardData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleImageSelect = (event) => {
    setpancardData({...pancardData, [event.target.name]: event.target.files[0]})
  }
  const onHandlePancard = (e) => {
    e.preventDefault();
    var form_data = new FormData();
    form_data.append('name', pancardData.name)
    form_data.append('pan_card_number', pancardData.pan_card_number)
    form_data.append('birth_date', pancardData.birth_date)   
    form_data.append('photo', pancardData.photo)
     
      onStorePancard(form_data).then((resp) => {
        if (resp.data.IsSuccess) {
          setpancardData(resp.data);
          toast.success(resp.data.Message);
          setTimeout(() => {
            navigate("/")
          }, 2500);
        } else {
          toast.error(resp.data.Message);
        }
      });
  }
  return (
    <>
      <div className={`MainScreen  ${toggleMenu ? 'show' : ''}`}>
        <MainHeader handleClick={handleClick} />
        <hr />
        <Sidebar />
        <div className='EarningPageBody'>
          <h6>Update Your Pan Card</h6>
          <p>Please update your correct pan-card, as we will cut TDS (Tax Deducted at Source!)</p>
          <Form onSubmit={onHandlePancard}>
            <Form.Group className='mb-3'>
              <Form.Label>Full Name</Form.Label>
              <Form.Control type="text" placeholder="Enter Your Full Name" onChange={onHandleChange}
                value={pancardData.name}
                name="name" />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Pan Card Number</Form.Label>
              <Form.Control type="text" placeholder="Enter Pan Card Number" onChange={onHandleChange}
                value={pancardData.pan_card_number}
                name="pan_card_number" />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Date Of Birth</Form.Label>
              <Form.Control type="date" placeholder="" onChange={onHandleChange}
                value={pancardData.birth_date}
                name="birth_date" />
            </Form.Group>
            <Form.Group className='mb-3'>
              <Form.Label>Upload Photo of your Pan Card.</Form.Label>
              <Form.Control type="file" placeholder="" onChange={handleImageSelect}
               name="photo" />
            </Form.Group>         
          <div className='mt-5'>
            <Button type='submit' className='btn btn-green d-block w-100 ' disabled={!pancardData.name || !pancardData.pan_card_number || !pancardData.birth_date || !pancardData.photo}>COMPLETE</Button>
          </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default PanCardPage;
