import { Button, Form, Image, InputGroup, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import "./LoginPage.css";
import { useEffect, useState } from "react";
import { onHandleLogin, onHandleRegistration, onVerifyOtp } from "./action";
import { setAuthHeader } from "../../AxiosApi";
import { toast } from "react-toastify";
import { useSearchParams } from 'react-router-dom';
import { onStoreRefer } from "../ProfilePage/action";

function LoginPage() {
  const [otp, setOtp] = useState('');
  const [phoneNumber, setPhoneNumber] = useState();
  const [userData, setUserData] = useState();
  const [authMethod, setAuthMethod] = useState('');
  const [timeLeft, setTimeLeft] = useState(null);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const referral_code = searchParams.get('referral_code')
  // console.log({referral_code});

  const onHandleChange = (event) => {
    const { value, name } = event.target;
    if (value.length <= 10) {
      setPhoneNumber(value);
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setOtp(value);
  };
  useEffect(() => {
    if(timeLeft===0){
      //  console.log("TIME LEFT IS 0");
       setTimeLeft(null)
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);

  const onHandleSendOtp = (e) => {
    e.preventDefault();
    if(phoneNumber) {
      if(phoneNumber.length === 10) {
          const data = {
            phone_number: phoneNumber,
          };
          onHandleLogin(data).then((resp) => {
            // console.log({ resp });
            if (resp.data.IsSuccess) 
            {
                setTimeLeft(10);
              setAuthMethod('login')
              setUserData(resp.data);
            } 
            else 
            {
              // console.log('else')
                onHandleRegistration(data).then((response) => {
                if (response.data.IsSuccess) 
                {
                  setTimeLeft(10);
                  setAuthMethod('register')
                  setUserData(response.data);          
                }
                 else 
                 {
                  toast.error("Your Account is Block.Please Contact to Support Team.")
                }
              });
            }
          });
        // }
      } else {
        toast.error("Please enter valid phone number.")
      }
    } else {
      toast.error("Please enter your phone number.")
    }
  };

  const onSubmitOtp = (e) => {
    e.preventDefault();
    const data = {
      user_id: userData.Data.id,
      otp: otp
    };
    // console.log({ data })
    onVerifyOtp(data).then((resp) => {
      if (resp.data.IsSuccess) {
        setAuthHeader(`Bearer ${resp.data.Data.api_token}`)
        localStorage.setItem('token', resp.data.Data.api_token);
        setUserData(resp.data);
        if(authMethod === 'register') {
          const referData = {
            referral_code: localStorage.getItem('referralCode'),
          };
          onStoreRefer(referData).then((resp) => {
            if(resp.data.IsSuccess) {
              localStorage.removeItem('referralCode')
            }
          })
        }
        navigate('/')
      } else {
        toast.error(resp.data.Message)
      }
    });
  };

  return (
    <>
      <div className="loginPage">
        <div className="Loginbox">
          <div className="LoginBoximage">
            <Image src="/image/ludoBanner.png" />
          </div>
          <Link className="BackBtn">
            <Icon icon="ic:baseline-arrow-back-ios-new" />
          </Link>
          {!userData ? (
            <Form onSubmit={onHandleSendOtp}>
              <h6>Sign in or Sign up</h6>
              <InputGroup className="mb-3" controlId="formBasicEmail">
                <InputGroup.Text id="basic-addon1">+91</InputGroup.Text>
                <Form.Control
                  type="number"
                  value={phoneNumber}
                  placeholder="Enter Phone Number"
                  onChange={onHandleChange}
                />
              </InputGroup>
             
              <Button
                type="submit"
                variant=""
                className="btn-green d-block text-center w-100 mt-4"
              >
                Continue
              </Button>
            </Form>
          ) : (
            <Form onSubmit={onSubmitOtp}>
              <InputGroup className="mb-3" controlId="formBasicEmail">
                <InputGroup.Text id="basic-addon1">OTP</InputGroup.Text>
                <Form.Control
                  type="number"
                  value={otp}
                  onChange={handleChange}
                />
              </InputGroup>

              <Button className="ResendOtp" variant="" disabled={timeLeft > 0} onClick={onHandleSendOtp}>
                Resend OTP {timeLeft}
              </Button>
              <Button
                type="submit"
                variant=""
                className="btn-green d-block text-center w-100 mt-4"
              >
                Continue
              </Button>
            </Form>
          )}
          <p className="Terms">
            By proceeding, you agree to our <a href="#">Terms of Use</a>,
            <a href="#">Privacy Policy</a> and that you are 18 years or older.
            You are not playing from Assam, Odisha, Nagaland, Sikkim, Meghalaya,
            Andhra Pradesh, or Telangana.
          </p>
        </div>
      </div>
    </>
  );
}

export default LoginPage;
