import { Button, Form, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import MainHeader from "../../Component/MainHeader/MainHeader";
import "./ProfilePage.css";
import { getAvatarList, onCheckUserKyc, onGetUserProfile, onStoreRefer, onUpdateProfile, onhandleLogout, onstoreavatar } from "./action";
import { toast } from "react-toastify";
import DialogBox from "../../Component/DailogBox/DailogBox";

function ProfilePage() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [emailShow, setEmailShow] = useState(false);
  const [nameEditable, setNameEditable] = useState(false);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [refer, setrefer] = useState("");
  const [image, setImage] = useState("");
  const [userInfo, setUserInfo] = useState({});
  const [avatar, setavatar] = useState([]);
  const [userKycDetail, setUserKycDetail] = useState({});
  const [uploadImage, setUploadImage] = useState({});
  const navigate = useNavigate();
  const handleClick = () => {
    setToggleMenu(!toggleMenu);
  };
  const [logoutClick, setlogoutClick] = useState(false);
  const handlelogoutClick = () => {
    setlogoutClick(!logoutClick);
    setToggleMenu(false);
  };

  useEffect(() => {
    getUserProfile();
    checkUserKyc();
  }, [0]);

  const checkUserKyc = () => {
    onCheckUserKyc().then((response) => {
      if (response?.data?.IsSuccess) {
        setUserKycDetail(response.data);
      }
    });
  };
  const getUserProfile = () => {
    onGetUserProfile().then((response) => {
      if (response?.data?.IsSuccess) {
        setUserInfo(response.data);
        setEmail(response.data.email);
        setUserName(response.data.name);
        setImage(response.data.image);
      }
    });
  };
  const onstoreavtar = (id) => {
    const data = {
      id: id,
    };
    onstoreavatar(data).then((resp) => {
      if (resp.data.IsSuccess) {
        setEyeClick1(!eyeClick1);
        setEyeClick(!eyeClick);
        getUserProfile();
        setImage(resp.data.Data.image)

      }
    });

  };
  const emailClick = () => {
    setEmailShow(!emailShow);
  };
  const handleNameChange = (event) => {
    setUserName(event.target.value);
  };

  const onEditInput = () => {
    setNameEditable(true);
  };

  const onSaveEditedName = () => {
    const data = {
      name: userName,
    };
    onUpdateProfile(data).then((resp) => {
      if (resp.data.IsSuccess) {
        toast.success("Username Updated.");
      } else {
        toast.error(resp.data.Message);
      }
    });
    setNameEditable(false);
  };

  const onSubmitEmail = (e) => {
    e.preventDefault();
    const data = {
      email: email,
    };
    if (!email) {
      toast.error("Please Enter An Email");
    } else {
      onUpdateProfile(data).then((resp) => {
        if (resp.data.IsSuccess) {
          toast.success("Email Updated.");
        }
        else {
          toast.error(resp.data.Message);
        }
      });
    }
    setNameEditable(false);
  };

  const onSubmitRefer = (e) => {
    e.preventDefault();
    const data = {
      referral_code: refer,
    };
    onStoreRefer(data).then((resp) => {
      if (resp.data.IsSuccess) {
        let userdata = userInfo
        userdata.is_referral = 1
        console.log({ userdata })
        setUserInfo({ ...userInfo, is_referral: 1 });
        // console.log({ resp })
        toast.success("Referral code has been added successfully.");
      }
      else {
        toast.error(resp.data.Message);
      }
    });

  };
  const handleCompleteKyc = () => {
    if (userKycDetail.is_uploaded === 0) {
      navigate('/AadharKYC');
    }
    else if (userKycDetail.is_uploaded === 1) {
      toast.warn("Your KYC is in pending.Please wait for Admin  Approval.");
    } else if (userKycDetail.is_uploaded === 2) {
      toast.success("Your KYC is completed.");
    } else if (userKycDetail.is_uploaded === 3) {
      toast.error("Your KYC is Rejected.Please re-upload your document.");
      setTimeout(() => {
        navigate('/AadharKYC');
      }, 3000);
    } else if (userKycDetail.is_verified === true) {
      toast.success("Your KYC Is Already Verified.")
    }
  }
  const [eyeClick, setEyeClick] = useState(false);
  const [eyeClick1, setEyeClick1] = useState(false);
  const handleeyeClick1 = () => {
    setEyeClick1(!eyeClick1);
    getAvatarList().then((response) => {
      console.log({ response });
      if (response?.data?.IsSuccess) {
        setavatar(response.data.Data);
      }
    });
  };
  const handleeyeClick = () => {
    setEyeClick(!eyeClick);
  };

  const onHandleProfileChange = (event) => {
    console.log({ event: event.target })
    setUploadImage(event.target.files[0])
    const imageData = new FormData()
    imageData.append('image', event.target.files[0])
    onUpdateProfile(imageData).then((resp) => {
      if (resp.data.IsSuccess) {
        setImage(resp.data.image)
        setEyeClick(false)
      }
    })
  }


  const [isOpen, setIsOpen] = useState(false);

  const handleOpenDialog = () => {
    // handleClick();
    setIsOpen(true);
  };



  const handleCancel = () => {
    setlogoutClick(false);
  };

  const handleConfirm = () => {
    onhandleLogout().then((resp) => {
      if (resp.data.IsSuccess) {
        localStorage.removeItem("token");
        toast.success("Logout Successfully");
        setTimeout(() => {
          navigate("/login");
        }, 1500);
      } else {
        toast.error(resp.data.Message);
      }
    });
  };
  return (
    <>
      <div className={`MainScreen  ${toggleMenu ? "show" : ""}`}>
        <MainHeader handleClick={handleClick} />
        <hr />
        <Sidebar />
        <div className="ProfileBody">
          <div className="ProfileInfo">
            <span>
              <Image src={image} />
              <Link to="#" onClick={handleeyeClick} ><Icon icon="mdi:edit-circle" color="#60a8ff" /></Link>
            </span>
            <small>+91{userInfo.phone_number}</small>
            <h6>
              {!nameEditable ? userName : (
                <input type="text" name="name" value={userName} onChange={handleNameChange} />
              )}
              {!nameEditable ? (
                <Link to="#" onClick={onEditInput}>
                  <Icon icon="mdi:edit-circle" color="#60a8ff" />
                </Link>
              ) : (
                <Link to="#" onClick={onSaveEditedName}>
                  <Icon icon="mdi:edit-circle" color="#60a8ff" />
                </Link>
              )}
            </h6>
          </div>
          <Link to="/wallet" className="btn btn-white btn-Wallet">
            <Icon icon="material-symbols:account-balance-wallet" /> My Wallet
          </Link>
          <br />
          <hr />
          <div className="AddahrProfile">
            <h6>Complete Profile</h6>

            <div className="BtnWrap">

              {!userKycDetail.is_verified === true ? (
                <Button variant='' className="btn btn-green" onClick={handleCompleteKyc}>
                  Complete KYC
                </Button>
              ) : <Button variant='' className="btn btn-green">
                Verified KYC
              </Button>}
              <Link className="btn btn-green" to="#" onClick={emailClick}>
                Update Email ID
              </Link>
            </div>
            <Form
              className={`emailUpdate d-flex align-items-end gap-2 mt-2 ${emailShow ? "show" : ""}`}
              onSubmit={onSubmitEmail}
            >
              <Form.Group className="w-100" controlId="formBasicEmail">
                <Form.Label className="text-white">
                  Upload Your Email
                </Form.Label>
                <Form.Control
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>
              {!userInfo.email ?
                <Button variant="" className="btn-green btn-email" type="submit" >Update</Button>
                :
                <Button variant="" className="btn-green btn-email" disabled>Update</Button>
              }
              {/* <Button type="submit" className="btn-green btn-email"  disabled={!userInfo.email}>Update</Button>  */}
            </Form>
          </div>
          <br />
          <hr />
          <br />
          <ul className="referCashBox">
            {userInfo.is_referral === 0 && <li>
              <i>
                <Icon icon="fluent-mdl2:add-friend" />
              </i>
              <Form.Group>
                <Form.Label>USE REFER CODE</Form.Label>
                <div className="d-flex align-items-center">
                  <Form.Control type="text" placeholder="" value={refer} name="refer"
                    onChange={(e) => setrefer(e.target.value)} />
                  <Link onClick={onSubmitRefer}>
                    <Icon icon="material-symbols:check" />
                  </Link>
                </div>
              </Form.Group>
            </li>}
            <li>
              <i>
                <Image src="/image/cashIcon.png" />
              </i>
              <h6>
                CASH WON
                <span>
                  <Icon icon="fluent-emoji:money-with-wings" /> ₹
                  {userInfo.cash_won}
                </span>
              </h6>
            </li>
            <li>
              <i>
                <Image src="/image/talvar.png" />
              </i>
              <h6>
                BATTLE PLAYED
                <span>
                  <Icon icon="fluent-emoji:money-with-wings" /> ₹
                  {userInfo.battles_played}
                </span>
              </h6>
            </li>
            <li>
              <i>
                <Image src="/image/img_potali.png" style={{ background: "#F06A63", borderRadius: "100%", color: "#fff !important" }} />
              </i>
              <h6>
                REFERRAL EARNING
                <span>
                  <Icon icon="fluent-emoji:money-with-wings" /> ₹
                  {userInfo.referral_balance}
                </span>
              </h6>
            </li>
          </ul>
          <br />
          <hr />
          <br />

          <div>
            <Sidebar handlelogoutClick={handlelogoutClick} />
            <Button className="btn btn-red" onClick={handlelogoutClick} >
              <Icon icon="uiw:logout" /> Logout
            </Button>

            <div div className={`Popupp  ${logoutClick ? "show blur-background" : ""}`}>
              {/* <div  className={isOpen ? "blur-background" : ""}>  */}
              <div className="box">
                <h1 className="text-center"><Icon icon="mdi:question-mark-circle" fontSize={56} color="#25B4A1" /></h1>
                <p className="text-dark text-center">Do You Want To Logout?</p>
                <Button className='btn btn-border-green ' style={{ margin: "auto 10px ", padding: "8px 28px" }} onClick={handleCancel}>Cancel</Button>
                <Button className='btn btn-green px-5 mx-1 my-1' onClick={handleConfirm}>Ok</Button>
                {/* <DialogBox isOpen={isOpen}  onClose={handleCloseDialog} onCancel={handleCancel}
              onConfirm={handleConfirm}>
              <h1 className="text-center"><Icon icon="mdi:question-mark-circle" fontSize={56} color="#25B4A1" /></h1>
              <p className="text-white text-center">Do You Want To Logout?</p>
            </DialogBox> */}
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>

      <div div className={`Popupp  ${eyeClick ? "show" : ""}`}>
        <h3>
          Change Your Profile Photo
          <Link to="#" onClick={handleeyeClick}>
            <Icon icon="material-symbols:close" />
          </Link>
        </h3>

        <div className="ChooseOption">
          <Link to="#" className="w-50Image" onClick={handleeyeClick1}>
            <span><Image src="/image/avtar.png" /></span>
            <h6>Avatar</h6>
          </Link>
          <label htmlFor="chooseGalary" className="w-50Image">
            <input id="chooseGalary" type="file" onChange={onHandleProfileChange} />
            <span><Icon icon="ooui:image-gallery" /></span>
            <h6>Gallery</h6>
          </label>
        </div>
      </div>

      <div div className={`Popupp  ${eyeClick1 ? "show" : ""}`}>
        <h3>
          Change Your Profile Photo
          <Link to="#" onClick={handleeyeClick1}>
            <Icon icon="material-symbols:close" />
          </Link>
        </h3>
        <div className="d-flex flex-wrap">
          {avatar?.map((ava, index) => (
            <div key={index}>
              <Link to="#" className="w-25Image" onClick={() => onstoreavtar(ava._id)}>
                <Image src={ava.image} />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default ProfilePage;