import { Accordion, Col, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import './DashBoardPage.css';
import { useEffect, useState } from 'react';
import Sidebar from '../../Component/Sidebar/Sidebar';
import MainHeader from '../../Component/MainHeader/MainHeader';
import HowToWinMoney from '../../Component/HowToWinMoney/HowToWinMoney';
import { getAllGames } from './action';

function DashBoardPage() {
  const [toggleMenu, setToggleMenu] = useState(false)
  const [gamelist, setGamelist] = useState([])
  const [app, setapp] = useState({})

  const getDownloadUrl = () => {
    const fileUrl = 'https://khelbros.com/khelbros.apk'; // replace with your file URL
    return fileUrl;
  };

  const handleClick = () => {
    setToggleMenu(!toggleMenu)
  }
  const [playPopup, setPlayPopup] = useState(false)
  const popupHandleClick = () => {
    setPlayPopup(!playPopup)
  }

  useEffect(() => {
    getAllGames().then((result) => {
      if (result.data.IsSuccess) {
        setGamelist(result.data.Data)
        setapp(result.data)
      }
    })
  }, [0])
  return (
    <>
      <div className={`MainScreen  ${toggleMenu ? 'show' : ''}`}>
        <MainHeader handleClick={handleClick} />
        <hr />
        <Sidebar />
        {app.custom_note === "" ? '' : (<p className='custom-note'>{app.custom_note}</p>)}
        <Link to="#"  className='VideoHelp'>
          <span><Icon icon="logos:youtube-icon" />VIDEO HELP</span>
          <p>How to win money?</p>
        </Link>
        {/* <hr /> */}
        <div className='DashboardMain'>
          <h5 style={{ color: "#25B4A1" }}>Games Tournaments</h5>
          <p className='gametournament'> <span><Image src="/image/talvar.png" style={{ width: "20px" }} /></span> is for Battles and <span><Image src="/image/word_cup.png" /></span>  is for Tournaments. Know more here.</p>
          {app.is_maintenance === 'yes' ?
            (<div className='maintenance'>
              <span><Image src="/image/img_maintanace.png" className='mx-50' /></span>
              <h6>Application Is Under Maintenance</h6>
              <p>Something new is coming! Please wait fornew update. Stay Tune with <b>khelbros family</b>.</p>
            </div>
            )
            : gamelist.length > 0 ?
              (<Row>
                {gamelist?.map((game, index) => (
                  <Col lg={6} md={6} sm={6} xs={6} key={index}>
                    <Link to={game.is_coming_soon === 0 ? `/gamelist/${game.name.replace(/\s+/g, '-').toLowerCase()}` : "/"} state={{ gameId: game._id }} className="HproductCard GameCard">
                      <div className="HproductCardImg">
                        <Image src={game.image} />
                      </div>
                      <div className="HproductCardInfo">
                        <h6>{game.name} <span><Image src="/image/talvar.png" /></span></h6>
                        {game.is_coming_soon === 0 ? (<p><span className="live"></span>Live</p>) : (<p className='text-danger'><span className="red"></span>Coming Soon</p>)}
                      </div>
                    </Link>
                  </Col>
                ))}
              </Row>             
              ) : (
                <div>
                  <Row>
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <Link to="/login" className="HproductCard GameCard">
                        <div className="HproductCardImg">
                          <Image src='/image/img_game_1.png' />
                        </div>
                        <div className="HproductCardInfo">
                          <h6>Ludo Classic <span><Image src="/image/talvar.png" /></span></h6>
                          <p><span className="live"></span> Live</p>
                        </div>
                      </Link>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <Link to="/login" className="HproductCard GameCard">
                        <div className="HproductCardImg">
                          <Image src='/image/Game-two.png' />
                        </div>
                        <div className="HproductCardInfo">
                          <h6>Ludo Popular <span><Image src="/image/talvar.png" /></span></h6>
                          <p><span className="live"></span> Live</p>
                        </div>
                      </Link>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <Link to="/login" className="HproductCard GameCard">
                        <div className="HproductCardImg">
                          <Image src='/image/Game-03.png' />
                        </div>
                        <div className="HproductCardInfo">
                          <h6>Ludo Quick <span><Image src="/image/talvar.png" /></span></h6>
                          <p className="text-danger"><span className="red"></span> Coming Soon</p>
                        </div>
                      </Link>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <Link to="/login" className="HproductCard GameCard">
                        <div className="HproductCardImg">
                          <Image src='/image/img_game_one.png' />
                        </div>
                        <div className="HproductCardInfo">
                          <h6>khelbros <span><Image src="/image/talvar.png" /></span></h6>
                          <p className="text-danger"><span className="red"></span> Coming Soon</p>
                        </div>
                      </Link>
                    </Col>
                    <Col lg={6} md={6} sm={6} xs={6}>
                      <Link to="/login" className="HproductCard GameCard">
                        <div className="HproductCardImg">
                          <Image src='/image/ludo_one_g.png' />
                        </div>
                        <div className="HproductCardInfo">
                          <h6>khelbros 1g <span><Image src="/image/talvar.png" /></span></h6>
                          <p className="text-danger"><span className="red"></span> Coming Soon</p>
                        </div>
                      </Link>
                    </Col>
                  </Row>
                </div>
              )
          }
                  <Row className='androidApp '>
                    <Link to='https://rkludo.com/rkludo.apk' download target='_blank' className="btn btn-green">  <Icon icon="uiw:android" fontSize={22} /> Download APP</Link>
                  </Row>
          {/* // {gamelist.length > 0 ?
          //   <Row>
          //     {gamelist?.map((game, index) => (
          //       <Col lg={6} md={6} sm={6} xs={6} key={index}>
          //         <Link to={game.is_coming_soon === 0 ? `/gamelist/${game.name.replace(/\s+/g, '-').toLowerCase()}` : "/"} state={{ gameId: game._id }} className="HproductCard GameCard">
          //           <div className="HproductCardImg">
          //             <Image src={game.image} />
          //           </div>
          //           <div className="HproductCardInfo">
          //             <h6>{game.name} <span><Image src="/image/talvar.png" /></span></h6>
          //             <p><span className="live"></span> {game.is_coming_soon === 0 ? 'Live' : 'Coming Soon'}</p>
          //           </div>
          //         </Link>
          //       </Col>
          //     ))}
          //   </Row> :
          //   <Row>
          //     <Col lg={6} md={6} sm={6} xs={6}>
          //       <Link to="/login" className="HproductCard GameCard">
          //         <div className="HproductCardImg">
          //           <Image src='/image/img_game_1.png' />
          //         </div>
          //         <div className="HproductCardInfo">
          //           <h6>Ludo Classic <span><Image src="/image/talvar.png" /></span></h6>
          //           <p><span className="live"></span> Live</p>
          //         </div>
          //       </Link>
          //     </Col>
          //     <Col lg={6} md={6} sm={6} xs={6}>
          //       <Link to="/login" className="HproductCard GameCard">
          //         <div className="HproductCardImg">
          //           <Image src='/image/Game-two.png' />
          //         </div>
          //         <div className="HproductCardInfo">
          //           <h6>Ludo Popular <span><Image src="/image/talvar.png" /></span></h6>
          //           <p><span className="live"></span> Live</p>
          //         </div>
          //       </Link>
          //     </Col>
          //     <Col lg={6} md={6} sm={6} xs={6}>
          //       <Link to="/login" className="HproductCard GameCard">
          //         <div className="HproductCardImg">
          //           <Image src='/image/Game-03.png' />
          //         </div>
          //         <div className="HproductCardInfo">
          //           <h6>Ludo Quick <span><Image src="/image/talvar.png" /></span></h6>
          //           <p><span className="live"></span> Live</p>
          //         </div>
          //       </Link>
          //     </Col>
          //     <Col lg={6} md={6} sm={6} xs={6}>
          //       <Link to="/login" className="HproductCard GameCard">
          //         <div className="HproductCardImg">
          //           <Image src='/image/img_game_one.png' />
          //         </div>
          //         <div className="HproductCardInfo">
          //           <h6>khelbros <span><Image src="/image/talvar.png" /></span></h6>
          //           <p><span className="live"></span> Live</p>
          //         </div>
          //       </Link>
          //     </Col>
          //     <Col lg={6} md={6} sm={6} xs={6}>
          //       <Link to="/login" className="HproductCard GameCard">
          //         <div className="HproductCardImg">
          //           <Image src='/image/ludo_one_g.png' />
          //         </div>
          //         <div className="HproductCardInfo">
          //           <h6>khelbros 1g <span><Image src="/image/talvar.png" /></span></h6>
          //           <p><span className="live"></span> Live</p>
          //         </div>
          //       </Link>
          //     </Col>
          //   </Row>} */}
        </div>
        <hr />
        <div className='TermsAccordion'>  <span><Image src='/image/logo.svg' /></span> </div>
        {/* <Accordion >
          <Accordion.Item eventKey="0">
            <Accordion.Header className='TermsAccordion'> <span><Image src='/image/logo.svg' /></span> <i>Terms, Privacy, Support</i></Accordion.Header>
            <Accordion.Body>
              <ul className='TermSLinkWrap'>
                <li><Link to="/TermsCondition">Terms & Condition</Link></li>
                <li><Link to="/PrivacyPolicy">Privacy Policy</Link></li>
                <li><Link to="/RefundPolicy">Refund/Cancellation Policy</Link></li>
                <li><Link to="/support">Contact Us</Link></li>
                <li><Link to="/ResponsibleGaming">Responsible Gaming</Link></li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion> */}
        <hr />
        {/* <div className='RkLuDoInfo'>
          <h6>About Us</h6><br />
          <p>khelbros is a real-money gaming product owned and operated by Mama Shakuni Private Limited ("khelbros" or "We" or "Us" or "Our").</p><br />
          <h6>Our Business & Products</h6><br />
          <p>We are an HTML5 game-publishing company and our mission is to make accessing games fast and easy by removing the friction of app-installs.</p>
          <p>khelbros is a skill-based real-money gaming platform accessible only for our users in India. It is accessible on <a target="_blank" href='https://www.rkludo.in/'>https://www.RKLudo.in</a>. On khelbros, users can compete for real cash in Tournaments and Battles. They can encash their winnings via popular options such as Paytm Wallet, Amazon Pay, Bank Transfer, Mobile Recharges etc.</p><br />
          <h6>Our Games</h6><br />
          <p>khelbros has a wide-variety of high-quality, premium HTML5 games. Our games are especially compressed and optimised to work on low-end devices, uncommon browsers, and patchy internet speeds.</p>
          <p>We have games across several popular categories: Arcade, Action, Adventure, Sports & Racing, Strategy, Puzzle & Logic. We also have a strong portfolio of multiplayer games such as Ludo, Chess, 8 Ball Pool, Carrom, Tic Tac Toe, Archery, Quiz, Chinese Checkers and more! Some of our popular titles are: Escape Run, Bubble Wipeout, Tower Twist, Cricket Gunda, Ludo With Friends. If you have any suggestions around new games that we should add or if you are a game developer yourself and want to work with us, don't hesitate to drop in a line at <a href='mailto:info@rkludo.com!'>info@rkludo.com!</a></p><br />
        </div> */}
        <div className={`MobilePopup  ${playPopup ? 'show' : ''}`}>
          <HowToWinMoney handleClose={popupHandleClick} />
        </div>
      </div>
    </>
  );
}

export default DashBoardPage;
