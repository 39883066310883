import Axios from "../../AxiosApi";

export const onAddCash = (addcashData) => {
  return Axios({
    method: "post",
    url: `transactions/store`,
    data: addcashData
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const ongetCashfree = (addcashData) => {
  return Axios({
    method: "post",
    url: `profile/generate-cashfree-token`,
    data: addcashData
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
