import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import MainHeader from "../../Component/MainHeader/MainHeader";
import StepWizard from "react-step-wizard";
import "./KycPage.css";
import WizardStep1 from "../../Component/WizardStep1/WizardStep1";
import WizardStep2 from "../../Component/WizardStep2/WizardStep2";
import WizardStep3 from "../../Component/WizardStep3/WizardStep3";
import moment from "moment";
import { onCompleteUserKyc } from "./action";

function KycPage() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [aadharNumError, setAadharNumError] = useState(false);
  const navigate = useNavigate()
  const [kycData, setKycData] = useState({
    aadhar_number: '',
    first_name: '',
    // last_name: '',
    birth_date: '',
    state_id: '',
    front_photo: '',
    back_photo: '',
  })
  const handleClick = () => {
    setToggleMenu(!toggleMenu);
  };
  const onHandleChange = (event) => {
    const { name, value } = event.target
    if (name === 'aadhar_number') {
      if (value.length !== 12) {
        setAadharNumError(true)
      } else {
        setAadharNumError(false)
      }
    }
    setKycData({ ...kycData, [name]: value })
  }

  const handleSelectDate = (date) => {
    const formatDate = moment(date).format('yyyy-MM-DD')
    // console.log({ formatDate })
    setKycData({ ...kycData, birth_date: formatDate })
  }

  const handleImageSelect = (event) => {
    // console.log({ event })
    setKycData({ ...kycData, [event.target.name]: event.target.files[0] })
  }

  const onSubmitKyc = () => {
    var form_data = new FormData();
    form_data.append('aadhar_number', kycData.aadhar_number)
    form_data.append('first_name', kycData.first_name)
    // form_data.append('last_name', kycData.last_name)
    form_data.append('birth_date', kycData.birth_date)
    form_data.append('state_id', kycData.state_id)
    form_data.append('front_photo', kycData.front_photo)
    form_data.append('back_photo', kycData.back_photo)

    // console.log({ form_data })
    onCompleteUserKyc(form_data).then((resp) => {
      // console.log({ resp })
      if (resp.data.IsSuccess) {
        navigate('/kyc-validation');
      }

    })
  }

  return (
    <>
      <div className={`MainScreen  ${toggleMenu ? "show" : ""}`}>
        <MainHeader handleClick={handleClick} />
        <hr />
        <Sidebar />
        <div className="KycPageBody">
          <StepWizard>
            <WizardStep1 onHandleChange={onHandleChange} aadharNumError={aadharNumError} kycData={kycData} />
            <WizardStep2 onHandleChange={onHandleChange} handleSelectDate={handleSelectDate} kycData={kycData} />
            <WizardStep3 onHandleChange={handleImageSelect} onSubmitKyc={onSubmitKyc} kycData={kycData} />
          </StepWizard>
        </div>
      </div>
    </>
  );
}

export default KycPage;
