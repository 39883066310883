import { Button ,Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./WizardStep2.css";
import { onGetAllState } from "../../Pages/KycPage/action";

const WizardStep2 = (props) => {
  const [allState, setAllState] = useState([]);
  const [selectedDate, setSelectedDate] = useState('');

  useEffect(() => {
    onGetAllState().then((resp) => {
      if (resp.data.IsSuccess) {
        setAllState(resp.data.Data);
      }
    });
  }, [0]);

  const selectDate = (date) => {
    setSelectedDate(date);
    props.handleSelectDate(date)
  }
  return (
    <>
      <div className="KycFrom">
        <h4>KYC Verification</h4>
        <p>Step 2 of 3</p>
        <small>
          Enter details of AadharCard: <span>{props?.kycData?.aadhar_number}</span>
        </small>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              name="first_name"
              placeholder="Enter Full Name"
              onChange={props.onHandleChange}
            />
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              name="last_name"
              onChange={props.onHandleChange}
            /> 
          </Form.Group>*/}
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Date of Birth</Form.Label>
            <DatePicker
              selected={selectedDate}
              dateFormat='dd-MM-yyyy'
              onChange={selectDate}
              placeholderText="DD-MM-YYYY"
            />
            {/* <Form.Control type="date" placeholder="" /> */}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>State</Form.Label>
            <Form.Select name='state_id' onChange={props.onHandleChange}>
              {allState.map((state, index) => (
                <option value={state._id}>{state.name}</option>
              ))}
            </Form.Select>
          </Form.Group>
        </Form>
      </div>
      <Button
        variant=""
        className="btn btn-green btn-block w-100 mt-5"
        disabled={props.kycData.first_name === '' || props.kycData.birth_date === '' || props.kycData.state_id === ''}
        onClick={props.nextStep}
      >
        Next
      </Button>
    </>
  );
};

export default WizardStep2;
