import { Accordion, Button, Col, Form, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useState } from 'react';
import Sidebar from '../../Component/Sidebar/Sidebar';
import MainHeader from '../../Component/MainHeader/MainHeader';
import './LegalTerm.css';
import TermsCondition from '../TermsCondition/TermsCondition';
import PrivacyPolicy from '../TermsCondition/PrivacyPolicy';
import RefundPolicy from '../TermsCondition/RefundPolicy';
import ResponsibleGaming from '../TermsCondition/ResponsibleGaming';

function LegalTerm() {
  const [toggleMenu, setToggleMenu] = useState(false)
  const handleClick = () => {
    setToggleMenu(!toggleMenu)
  }
  const handleChat = () => {
    const script = document.createElement('script');
    script.src = '//code.tidio.co/v4oqp2a9ttw0xc8cwr1nv4bda9k0v2mm.js';
    // script.setAttribute("chat", "true");
    script.async = true;
    document.body.appendChild(script);
  }
  return (
    <>
      <div className={`MainScreen  ${toggleMenu ? 'show' : ''}`}>
        <MainHeader handleClick={handleClick} />
        <hr />
        <Sidebar />
        <div className='AddCashBody'>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header className='TermsAccordion'>Term & conditions</Accordion.Header>
              <Accordion.Body>
                <TermsCondition />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className='TermsAccordion'>Refund/Cancellation policy</Accordion.Header>
              <Accordion.Body>
                <RefundPolicy />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header className='TermsAccordion'>Privacy Policy</Accordion.Header>
              <Accordion.Body>
                <PrivacyPolicy />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header className='TermsAccordion'>About Us</Accordion.Header>
              <Accordion.Body>
                <div className='TermsBody'>
                  <p>RK Ludo is a real-money gaming product owned and operated by Mama Shakuni Private Limited ("RK Ludo" or "We" or "Us" or "Our").</p><br />
                  <h6>Our Business & Products</h6><br />
                  <p>We are an HTML5 game-publishing company and our mission is to make accessing games fast and easy by removing the friction of app-installs.</p>
                  <p>RK Ludo is a skill-based real-money gaming platform accessible only for our users in India. It is accessible on <a target="_blank" href='https://www.rkludo.in/'>https://www.RKLudo.in</a>. On RK Ludo, users can compete for real cash in Tournaments and Battles. They can encash their winnings via popular options such as Paytm Wallet, Amazon Pay, Bank Transfer, Mobile Recharges etc.</p><br />
                  <h6>Our Games</h6><br />
                  <p>RK Ludo has a wide-variety of high-quality, premium HTML5 games. Our games are especially compressed and optimised to work on low-end devices, uncommon browsers, and patchy internet speeds.</p>
                  <p>We have games across several popular categories: Arcade, Action, Adventure, Sports & Racing, Strategy, Puzzle & Logic. We also have a strong portfolio of multiplayer games such as Ludo, Chess, 8 Ball Pool, Carrom, Tic Tac Toe, Archery, Quiz, Chinese Checkers and more! Some of our popular titles are: Escape Run, Bubble Wipeout, Tower Twist, Cricket Gunda, Ludo With Friends. If you have any suggestions around new games that we should add or if you are a game developer yourself and want to work with us, don't hesitate to drop in a line at <a href='mailto:info@rkludo.com!'>info@rkludo.com!</a></p><br />
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header className='TermsAccordion'>Responsible Gaming</Accordion.Header>
              <Accordion.Body>
                <ResponsibleGaming />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header className='TermsAccordion'>Platform Commission</Accordion.Header>
              <Accordion.Body>
                <div className='TermsBody'>
                  <p>Platfrom commission is taken by each participant equally in every contest or battle. Commission of any battle/contest is shown to participant before joining the contest/battle.</p><br />
                  <h6>Below 500 cash games,</h6>
                  <p>We charge 5-30% commission depending on the entry amount.</p>
                  <h6>Above 500 cash games,</h6>
                  <p>We charge flat 5% commission on each battle.
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header className='TermsAccordion'>TDS Policy</Accordion.Header>
              <Accordion.Body>
                <div className='TermsBody'>
                  <p>Last Updated: 31th Mar 2023</p>
                  <p>In line with the provisions contained in Finance Act, 2023, following TDS policy is applicable for all players on RKLudo from 1 Apr, 2023.</p>
                  <ul>
                    <li>TDS is applicable at the time of withdrawal or deposit refund</li>
                    <li>30% TDS is applicable on any positive net winnings at the time of withdrawal</li>
                    <li>TDS is also applicable at the end of financial year on year end wallet balance by considering it as a withdrawal amount. Post applying TDS on the remaining balance (as per policy), the remaining amount will be carried forward to the next financial year as deposit balance. Carried forward balance will be considered as investment for next year and TDS would not be applicable for that amount.</li>
                  </ul>
                  <p>Here, Net winnings = Total Withdrawals - Total Deposits (in a financial year). Apr 1 to Mar 31 duration is considered a financial year.</p>
                  <h4>How was TDS deducted before Apr 1, 2023 :</h4>
                  <ul>
                    <li>Before Apr 1 2023, TDS was deducted at a game level when the winnings were greater than 10K.</li>
                  </ul>
                  <p>Example : Previously if I won Rs 11000 in a game, then 30% TDS will be deducted i.e. 3300 and hence Rs 7700 will be credited in the withdrawal wallet.</p>
                  <h4>How is the current TDS policy different from the last TDS policy of FY 2022- 2023</h4>
                  <ul>
                    <li>Post Apr 1, if I win some amount let's assume Rs 11000, NO TDS will be deducted while crediting that amount in the withdrawal wallet. Entire Rs 11000 will be transferred to the winnings wallet.</li>
                    <li>Only when the user will try to withdraw any amount from the wallet, we will look at the net winnings and basis that calculate the TDS applicable.</li>
                    <li>Hence we will deduct TDS only when the withdrawal running total is more than the Deposits. If the user is in loss, there will be no TDS. Hence this will be beneficial to the users.</li>
                    Please note that the TDS Policy is based on the understanding of the provisions as introduced/ amended by Finance Act, 2023 and the Company reserves its right to modify/ change/ amend the TDS Policy basis law applicable at the relevant time. Attached is the finance bill amendment which says that the policy will be applicable from Apr 1, 2023 rather than July 1, 2023.
                  </ul>
                  <h4>What Does It Mean For Players?</h4>
                  <p>Find below a few scenarios to understand the application of the aforementioned TDS Policy.</p>
                  <p><b>Scenario 1:</b> If your net winnings (including the withdrawal amount) is more than 0, then 30% TDS will be deducted on the net winnings at the time of withdrawal.</p>
                  <b>Example:</b>
                  <ul>
                    <li>Total withdrawals in the financial year(A)	₹5,000</li>
                    <li>Total deposits in the financial year(B)	₹10,000</li>
                  </ul>
                  In the above scenario, if you want to withdraw ₹7,000 , then the net winnings will be:
                  <ul>
                    <li>
                      Amount being withdrawn by the player(C)	₹7,000</li>
                    <li>Net winnings(A+C - B)	₹2,000</li>
                    <li>30% TDS Applicable on Net Winnings(D)	₹600</li>
                    <li>Amount to be credited in the bank account(C-D)	₹6,400</li>
                  </ul>
                  <b>Zero TDS Withdrawal amount:</b> If you withdraw upto ₹5,000 , then there will be zero TDS deducted as net winnings will be ₹0 (₹5,000 + ₹5,000 - ₹10,000). See calculation below:
                  <ul>
                    <li>Total withdrawals in the financial year(A)	₹5,000</li>
                    <li>Total deposits in the financial year(B)	₹10,000</li>
                    <li>Amount being withdrawn by the player(C)	₹5,000</li>
                    <li>Net winnings(A+C - B)	₹0</li>
                  </ul>
                  <p><b>Scenario 2: </b>If you have paid TDS in previous withdrawals, then no TDS is applicable on withdrawals till your net winnings crosses the amount for which you have already paid TDS.</p>
                  <b>Example:</b>
                  <ul>
                    <li>Total withdrawals in the financial year	₹20,000</li>
                    <li>Total deposits in the financial year	₹20,000</li>
                    <li>TDS paid in the financial year

                      (TDS paid on winnings of ₹6,000 as initially you had positive net winnings of ₹6,000)

                      ₹1,800</li>
                  </ul>
                  <p>In this scenario, No TDS applicable till your net winnings crosses ₹6,000 (winnings already taxed):</p>
                  <ul>
                    <li><b>Zero TDS Withdrawal amount:</b> If you withdraw up to ₹6,000, no TDS is applicable</li>
                    <li>If you withdraw ₹10, 000, then 30% TDS is applicable only on ₹4,000 winnings i.e. ₹1,200.</li>
                  </ul>
                  <p><b>Scenario 3:</b> You have paid TDS on initial winnings during the financial year but your net winnings have reduced post that.</p>
                  <b>Example:</b>
                  <ul>
                    <li>Total withdrawals in the financial year(A)	₹40,000</li>
                    <li>Total deposits in the financial year(B)	₹20,000</li>
                    <li>Net winnings in the financial year(A-B)	₹20,000</li>
                    <li>TDS paid till date	₹6,000</li>
                  </ul>
                  <p>Post this, if your net winnings reduces to ₹10,000, then, subject to applicable law, the excess TDS deducted can be claimed as refund in your annual income tax filing.</p>

                  <p><b>Scenario 4: </b>At the end of the financial year, if you have an amount in your account, then TDS will be calculated on that amount by considering it as a withdrawal of the financial year and remaining amount will be carried forward to next year as starting deposit amount. Withdrawal from the starting deposit amount will not be subject to TDS in the next year.</p>
                  <b>Example:</b>
                  <ul>
                    <li>Total withdrawals in the financial year(A)	₹40,000</li>
                    <li>Total deposits in the financial year(B)	₹40,000</li>
                    <li>TDS paid till date(C)  ₹1,500

                      (on winning amount of ₹5,000 as initially you had positive net winnings of ₹5,000)

                    </li>
                    <li>Account balance at the end of Mar 31, 2024(D)	₹30,000</li>

                  </ul>
                  <p>In above scenario, following calculation is done:</p>
                  <ul>
                    <li>Total withdrawals in the financial year(A+D)	₹70,000</li>
                    <li>Total deposits in the financial year(B)	₹40,000</li>
                    <li>Net winnings(A+D-B)	₹30,000</li>
                    <li>30% TDS Applicable on Net Winnings(E)	₹9,000</li>
                    <li>TDS paid till date(C)	₹1,500</li>
                    <li>TDS Remaining to be paid(E-C)	₹7,500</li>
                  </ul>
                  <p>Thus, ₹7,500 TDS is deducted from your account balance at the end of Mar 31, 2024 and remaining balance of ₹22,500 will be carried forward to the next financial year as deposit amount. You will not have to pay TDS in the next financial year on withdrawal out of this amount.</p>
                  <p><b>Special Note:</b> In case you have an amount on the table at the end of the financial year, then it will not be considered in next year's starting balance.</p>

                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
              <Accordion.Header className='TermsAccordion'>Contact Us</Accordion.Header>
              <Accordion.Body>
                <div className='SupportBody'>
                  <Image src='/image/ContactUs.png' />
                  <h6 className='text-dark'>Contact Us at below platforms. </h6>
                  <div className='supportChat'>
                    <Button className='btn btn-green d-block w-100 text-center' onClick={handleChat}><Icon icon="material-symbols:chat" className="m-1" />Live Chat</Button>
                    <a className='btn btn-instagram d-block w-100 text-center my-3' href='https://www.instagram.com/rkludo_/' ><Icon icon="mdi:instagram" className="m-1" />Connect On Instagram</a>
                    <a className='btn btn-outline-green d-block w-100 text-center border border-dark' href='mailto:info@rkludo.com' ><Icon icon="mdi:email" className="m-1" />Reach Us Via Email</a>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
}

export default LegalTerm;
