import Axios from "../../AxiosApi";

export const onGetUserProfile = () => {
  return Axios({
    method: "get",
    url: `profile/view`,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const onCheckUserKyc = () => {
  return Axios({
    method: "get",
    url: `profile/check-kyc`,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getAvatarList = () => {
  return Axios({
    method: "get",
    url: `profile/get-avatar`,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

export const onUpdateProfile = (profileData) => {
  return Axios({
    method: "post",
    url: `profile/store`,
    data: profileData
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const onStoreRefer = (referData) => {
  return Axios({
    method: "post",
    url: `referral/store`,
    data: referData
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const onstoreavatar = (avatar) => {
  return Axios({
    method: "post",
    url: `profile/store-avatar`,
    data: avatar
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const onhandleLogout = () => {
  return Axios({
    method: "get",
    url: `kk-logout`,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}