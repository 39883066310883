import Axios from "../../AxiosApi";

export const onGameHistory = (HistoryData) => {
    return Axios({
      method: "post",
      url: `games/history`,
      data: HistoryData
    })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
  };
  