import { Button, Form, Image } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Sidebar from '../../Component/Sidebar/Sidebar';
import MainHeader from '../../Component/MainHeader/MainHeader';
import './BankWithDraw.css';
import { onWithdrawal } from '../WithDrawPage/action';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { ongetSetting } from '../EarningPage/action';

function BankWithDraw() {
  const { amount } = useParams();
  const location = useLocation();
  const [toggleMenu, setToggleMenu] = useState(false)
  const [showDiv, setShowDiv] = useState(false);
  const [Setting, setSetting] = useState({});
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(null);
  const [BankData, setBankData] = useState({
    amount: amount,
    bank_name: "",
    account_number: "",
    confirm_number: "",
    account_name: "",
    ifsc_code: "",
  });
  useEffect(() => {
    getStatus()
    if (showDiv) {
      const redirectTimeout = setTimeout(() => {
        navigate("/");
      }, 1000);

      return () => {
        clearTimeout(redirectTimeout);
      };
    }
  }, [showDiv]);
  useEffect(() => {
    if(timeLeft===0){
      //  console.log("TIME LEFT IS 0");
       setTimeLeft(null)
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);
  const getStatus = () => {
    ongetSetting().then((response) => {
      if (response?.data?.IsSuccess) {
        setSetting(response.data.Data.withdraw);
      }
    });
  };
  const handleClick = () => {
    setToggleMenu(!toggleMenu)
  }
  function onHandleChange({ target }) {
    const { name, value } = target;

    setBankData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  const onHandleBank = (e) => {
    e.preventDefault();
    if (!BankData.amount || !BankData.bank_name || !BankData.account_number || !BankData.account_name || !BankData.ifsc_code) {
      toast.error('Please Enter the values.');
    }
    else if (BankData.confirm_number !== BankData.account_number) {
      toast.error('Account Number do not match');
    } else {
      const data = {
        amount: BankData.amount,
        payment_method: "Bank",
        bank_name: BankData.bank_name,
        account_number: BankData.confirm_number,
        account_name: BankData.account_name,
        ifsc_code: BankData.ifsc_code
      };
      onWithdrawal(data).then((resp) => {
        if (resp.data.IsSuccess) {
          setBankData(resp.data);
          toast.success(resp.data.Message);
          setShowDiv(true);
          setTimeLeft(1);
        } else {
          toast.error(resp.data.Message);
        }
      });
    }
  }
  return (
    <>
      <div className={`MainScreen  ${toggleMenu ? 'show' : ''}`}>
        <MainHeader handleClick={handleClick} />
        <hr />
        <Sidebar />
        <div className='AddCashBody'>
          <h4>Withdraw</h4>
          <Form onSubmit={onHandleBank}>
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <Form.Label>Enter Amount</Form.Label>
              <Form.Control type="number" placeholder="Enter ₹"
                onChange={onHandleChange}
                value={BankData.amount}
                name="amount" />
              <Form.Text className="">
                Min : {Setting.min}, Max: {Setting.max}
              </Form.Text>
            </Form.Group>
            <h6 className='text-white mb-2'>Withdraw through Bank</h6>
            <Form.Group className="mb-2" controlId="formBasicEmail">
              <Form.Label>Account Holder Name</Form.Label>
              <Form.Control type="text" placeholder="Enter Account Holder Name" onChange={onHandleChange}
                value={BankData.account_name}
                name="account_name" />
            </Form.Group>
            <Form.Group className="mb-2" controlId="formBasicEmail">
              <Form.Label>Account Number</Form.Label>
              <Form.Control type="password" placeholder="Enter Account Number" onChange={onHandleChange}
                value={BankData.account_number}
                name="account_number" />
            </Form.Group>
            <Form.Group className="mb-2" controlId="formBasicEmail">
              <Form.Label>Re-Enter Account Number</Form.Label>
              <Form.Control type="number" placeholder="Re-Enter Account Number" onChange={onHandleChange}
                value={BankData.confirm_number}
                name="confirm_number" />
            </Form.Group>
            <Form.Group className="mb-2" controlId="formBasicEmail">
              <Form.Label>Bank Name</Form.Label>
              <Form.Control type="text" placeholder="Enter Bank Name" onChange={onHandleChange}
                value={BankData.bank_name}
                name="bank_name" />
            </Form.Group>
            <Form.Group className="mb-2" controlId="formBasicEmail">
              <Form.Label>IFSC Code</Form.Label>
              <Form.Control type="text" placeholder="Enter IFSC Code" onChange={onHandleChange}
                value={BankData.ifsc_code}
                name="ifsc_code" />
            </Form.Group>
            {showDiv && (
              <div className='upiBox'>
                <h6><span><Image src='/image/UIPIcon.svg' /></span>{BankData.account_number}</h6>
                <i><Icon icon="mdi:tick-decagram" color="#25B4A1" /></i>
                <p>Payment Withdrawal Request Success <br /> <small>Redirect in {timeLeft} Sec</small></p>
              </div>)}
            <hr />
            <Button className='btn btn-green d-block w-100 text-center mt-5' type='submit'  disabled={timeLeft > 0}>SUBMIT</Button>

          </Form>
        </div>
      </div>
    </>
  );
}

export default BankWithDraw;
