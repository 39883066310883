
import { useState } from 'react';
import MainHeader from '../../Component/MainHeader/MainHeader';
import Sidebar from '../../Component/Sidebar/Sidebar';
import './TermsCondition.css';

function Rules() {
    const [toggleMenu, setToggleMenu] = useState(false)
    const handleClick = () => {
        setToggleMenu(!toggleMenu)
    }
    return (
        <>
        <div className={`MainScreen  ${toggleMenu ? "show" : ""}`}>
          <MainHeader handleClick={handleClick} />
          <hr />
          <Sidebar />
            <div className='TermsBody' style={{color:"white"}}>
            <p class="p1"><b>Updated Game Rules ( 2023)</b></p>
        <p class="p4">If a single token of Opponent gets opened after joining the game and you leave immediately for any reason, then you will be directly 30% Loss!  Even if you autoexit with your life, you will be made 100% loss!  If someone's bite is not open in both the players, then we can cancel it!</p>
        <p class="p4">If one token is outside and near the house then 30% loss will be given but if the game is played and 2 pieces are out then 100% loss will be given to the one who left the game.</p>
        <p class="p4">  If 1 token is out in Autoexit then the game can be canceled but if you have left the game knowingly then you will be given Loss only, in this the final decision will be of Admin!</p>
        <p class="p4"> If you think that Opponent intentionally left the game in Autoexit but Admin canceled it, then you will be asked for video proof, so it is necessary to record every game!  If you do not give video proof, then the game result will be updated according to the admin, whether you win or cancel the game!</p>
        <p class="p4"> It is necessary to enter the result within 15 minutes after the end of the game, otherwise the game will be updated based on the result of the opponent, whether you win or lose, and you will be fully responsible for this, no changes can be made later!</p>
        <p class="p4"> If you put the wrong screenshot after winning, then the game will be canceled directly, so if you have forgotten to take the screenshot, first contact the admin in Live Chat and only then post the result according to their instructions!</p>
        <p class="p4">If the token (cut) of both the players has not come out of the house, then the game can be canceled by being left!  [VIDEO WILL BE REQUIRED TO PROOF CANCELLATION]</p>
        <p class="p4"> After entering the 'Cancel' result, if you play the game and win, then we will not be responsible for it, so do not start the game after canceling, otherwise it will be considered canceled.</p>
        <p class="p4"> Once the result is posted, it cannot be changed, so post the result after thinking carefully. Penalty will also be imposed for putting the wrong result, whether you have posted it by mistake or by spending your life!</p>
        <p > <b>Commission Rates:</b></p>
        <ol type="1">
            <li>Fight below ₹ 250, 10% commission will be charged on the fight amount.

            </li>
            <li>Battles between 250₹ to 500₹, flat 25₹ commission will be charged.</li>
            <li>Battles above ₹ 500, 5% commission will be charged on the battle amount.</li>
        </ol>
      </div>
            {/* </div> */}
            </div>
            
        </>
    );
}

export default Rules;
