import { Form, Image } from "react-bootstrap";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import MainHeader from "../../Component/MainHeader/MainHeader";
import "./GameList.css";
import { getAllGames } from "../DashBoardPage/action";
import { isEmpty } from "lodash";

import {
  onAddNewBettle,
  onCancelGameStarted,
  onCheckBettleRequest,
  onGetAllOpenBattles,
  onGetAllRunningGames,
  onStartTheGame,
} from "./action";
import { toast } from "react-toastify";

let isCenceled = false;
let isStarted = false;


function GameList() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [gameDetail, setGameDetail] = useState({});
  const [allRunningGames, setAllRunningGames] = useState([]);
  const [allOpenBattles, setAllOpenBattles] = useState([]);
  const [amount, setAmount] = useState('');
  const [newlyStartedBettle, setNewlyStartedBettle] = useState({});
  const [gameId, setGameId] = useState([]);
  const [playerLoader, setPlayerLoader] = useState(false);
  const [startLoader, setStartLoader] = useState(false);
  const [isFoundPlayer, setIsFoundPlayer] = useState(false);
  const [isPlaySound, setISPlaySound] = useState(false);
  const navigate = useNavigate();
  const { gameType } = useParams();
  const location = useLocation();
  const handleClick = () => {
    setToggleMenu(!toggleMenu);
  };
  const [audio] = useState(new Audio('/connect_player.mp3'));
  const [setaudio] = useState(new Audio('/battle_set_sound.mpeg'));

  useEffect(() => {
    if (!location?.state?.gameId) {
      navigate("/");
      return;
    }
    getAllGames().then((result) => {
      if (result.data.IsSuccess) {
        const filtered = result.data.Data.filter(
          (item) => item.name.replace(/\s+/g, "-").toLowerCase() === gameType
        );
        setGameDetail(filtered[0]);
      }
    });
    setGameId(location.state.gameId);
    const game = {
      game_id: location.state.gameId,
    };

    onGetAllRunningGames(game).then((resp) => {
      if (resp.data.IsSuccess) {
        setAllRunningGames(resp.data.Data);
      }
    });
  }, [0]);

  const getAllOpenBattles = () => {
    const game = {
      game_id: location.state.gameId,
    };
    onGetAllOpenBattles(game).then((resp) => {
      if (resp.data.IsSuccess) {
        setAllOpenBattles(resp.data.Data);
      }
    });
  };

  useEffect(() => {
    const interval = setInterval(() => getAllOpenBattles(), 1000);
    return () => {
      clearInterval(interval);
    };
  }, [0]);

  const onSetBettleAmount = (event) => {
    const { value } = event.target;
    setAmount(value);
  };


  // const [isCenceled, setIsCenceled] = useState(false);
  const [callNewBettle, setCallNewBettle] = useState(false);
  let checkRequestInterval;
  let checkStartRequestInterval;

  useEffect(() => {
    // const interval = setInterval(() => getAllOpenBattles(), 3000);
    // return () => {
    //   clearInterval(interval);
    // };
    if (callNewBettle) {
      const gameAmount = localStorage.getItem("gameAmount")
      onAddBettleRequest(gameAmount, gameId);
      setCallNewBettle(false);
    }
  }, [callNewBettle]);

  const doAdd = (gameData) => {
    onAddNewBettle(gameData).then((resp) => {
      if (resp.data.IsSuccess) {
        setISPlaySound(true)
        audio.play();
        setPlayerLoader(true);
        setNewlyStartedBettle(resp.data.Data);
        const data = {
          id: resp.data.Data._id,
        };

        checkRequestInterval = setInterval(() => {
          onCheckBettleRequest(data).then((response) => {
            const checkData = response.data.Data;
            if (response.data.IsSuccess) {
              // console.log('Add IF');
              setNewlyStartedBettle(checkData);
              if (checkData.to_name) {
                if(isPlaySound) {
                  setISPlaySound(false)
                  setaudio.play();
                 
                }
                setPlayerLoader(false);
              }
              if (isStarted) {
                isStarted = false
                clearInterval(checkRequestInterval);
              }
            } else {
              if (isCenceled) {
                // console.log('Add else IF');
                isCenceled = false
                setNewlyStartedBettle({});
                clearInterval(checkRequestInterval);
              } else {
                // console.log('Add else ELSE');
                clearInterval(checkRequestInterval);
                setCallNewBettle(true);
                setNewlyStartedBettle({});
                setPlayerLoader(false);
              }
            }
          });
        }, 1000);

        // return () => clearInterval(checkRequestInterval);
      } else {
        toast.error(resp.data.Message)
        // console.log('ELSE')
        setNewlyStartedBettle(resp.data.Data);
      }
    });
  }
  const onAddBettleRequest = (gameAmount, gameStartId, lagiKeNai) => {
    if (gameAmount > 0 && gameAmount !== '') {
      setAmount('')
      localStorage.setItem('gameAmount', gameAmount)
      if(isEmpty(newlyStartedBettle)) {
        audio.play();
        const gameData = {
          amount: gameAmount,
          game_id: gameStartId,
        };
        doAdd(gameData)
        toast.success("Battle set successfully.")
      } else {
        // console.log({ to_name: newlyStartedBettle.to_name})
        if(!newlyStartedBettle.to_name) {
          // console.log('IF')
          onCancelCreatedGame(newlyStartedBettle._id, 'other')
          const gameData = {
            amount: gameAmount,
            game_id: gameStartId,
          };
          doAdd(gameData)
        }
      }
    } else {
      toast.error('Please enter amount first')
    }
  };

  const doCancel = (cancelData) => {
    onCancelGameStarted(cancelData).then((resp) => {
      if (resp.data.IsSuccess) {
        // localStorage.removeItem('gameAmount')
        // clearInterval(checkRequestInterval);
        setNewlyStartedBettle({});
      }
    });
  };

  const onCancelCreatedGame = (id, type) => {
    // console.log({ type });
    const cancelData = {
      game_id: id,
    };
    isCenceled = true
    if(type === 'cancel') {
      localStorage.removeItem('gameAmount')
    }
    // setIsCenceled(true);
    doCancel(cancelData)
    toast.success("Battle Cancelled")
  };
  const onStartGame = (id) => {
    isStarted = true
    const startData = {
      game_id: id,
    };
    setStartLoader(true);
    onStartTheGame(startData).then((resp) => {
      if (resp.data.IsSuccess) {
        const data = {
          id: newlyStartedBettle._id,
        };
        checkStartRequestInterval = setInterval(() => {
          onCheckBettleRequest(data).then((response) => {
            const checkData = response.data.Data;
            setNewlyStartedBettle(checkData);
            if (response.data.IsSuccess) {
              // console.log('start IF');
              setNewlyStartedBettle(checkData);
              // toast.success("Battle started.")
              if (checkData.room_id) {
                setStartLoader(false);
                localStorage.removeItem('gameAmount')
                toast.success("Battle started.")
                navigate("/roomcode", { state: { gameId: checkData.room_id } });
                clearInterval(checkStartRequestInterval);
              }
            } else {
              if (isCenceled) {
                // console.log('start elese IF');
                isCenceled = false
                setNewlyStartedBettle({});
                setStartLoader(false);
                clearInterval(checkStartRequestInterval);
                toast.success("Battle cancelled.")
              } else {
                // console.log('start elese ELSE');
                clearInterval(checkStartRequestInterval);
                setCallNewBettle(true);
                setNewlyStartedBettle({});
                setStartLoader(false);                
              }
            }
          });
        }, 1000);

        return () => clearInterval(checkRequestInterval);
      }
    });
  };

  return (
    <>
      <div className={`MainScreen  ${toggleMenu ? "show" : ""}`}>
        <MainHeader handleClick={handleClick} />
        <hr />
        <Sidebar />
        <div className="GameListBody">
          <div className="createBattle">
            <h6 className="text-white text-center" style={{fontSize: "14px"}}>CREATE A BATTLE!</h6>
            <div className="d-flex gap-3 max-400">
              <Form.Control
                type="number"
                placeholder="Amount"
                name="amount"
                value={amount}
                onChange={onSetBettleAmount}
              />
              <Link
                className="btn btn-green noRadius d-flex align-items-center"
                to="#"
                onClick={() => onAddBettleRequest(amount, gameId)}
              >
                Set
              </Link>
            </div>
          </div>
          <hr />
          <div className="GameListMain">
            <div className="GameListHeader">
              <h6>
                <span>
                  <Image src="/image/talvar.png" />
                </span>
                Open Battles
              </h6>
              <Link to="/Rules">
                Rules <Icon icon="mdi:information-variant-circle-outline" />
              </Link>
            </div>
            {!isEmpty(newlyStartedBettle) ? (
              <div className="GameListBody">
                <div className="GameListItem">
                  <div className="GameRunningHeader">
                    <h6>
                      PLAYING FOR
                      <i>
                        <Icon icon="fluent-emoji:money-with-wings" />
                      </i>
                      {newlyStartedBettle.amount}
                    </h6>
                    <div className="d-flex"> 
                      {newlyStartedBettle.to_name ? (
                        <>
                          {!startLoader ? <Link
                            to="#"
                            className="btn btn-brightgreen"
                            onClick={() => onStartGame(newlyStartedBettle._id)}
                          >
                            START

                          </Link> :
                            <Image src="/image/loader.gif" width={20} className="me-3" />
                          }
                          <Link
                            to="#"
                            className="btn btn-red ms-2"
                            onClick={() =>
                              onCancelCreatedGame(
                                newlyStartedBettle._id,
                                "reject"
                              )
                            }
                          >
                            REJECT
                          </Link>
                        </>
                      ) : (
                        <>
                          <Link
                            to="#"
                            className="btn btn-green"
                            onClick={() =>
                              onCancelCreatedGame(
                                newlyStartedBettle._id,
                                "cancel"
                              )
                            }
                          >
                            CANCEL
                          </Link>
                        </>
                      )}
                    </div>
                    {/* {game.show_view_btn ? <Link className="btn btn-green">View</Link> :
                     <h6 className="">PRIZE <Icon icon="fluent-emoji:money-with-wings" /> {game.amount}</h6>} */}
                  </div>
                  <div className="GameRunningBody">
                    <div className="GameProfile">
                      <span>
                        <Image src={newlyStartedBettle.from_image} />
                      </span>
                      <p>{newlyStartedBettle.from_name}</p>
                    </div>
                    <i>
                      <Image src="/image/verses.png" />
                    </i>
                    {!playerLoader ? (
                      <div className="GameProfile">
                        <span>
                          <Image src={newlyStartedBettle.to_image} />
                        </span>
                        <p>{newlyStartedBettle.to_name}</p>
                      </div>
                    ) : (
                      <div className="GameProfile">
                        <span>
                          <Image src="/image/loader.gif" />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {allOpenBattles.length > 0 &&
              allOpenBattles.map((game, index) => (
                <div className="GameListBody" key={index}>
                  {/* {console.log({ allOpenBattles: game })} */}
                  <div className="GameListItem">
                    <h6>
                      CHALLENGE FROM <span>{game.from_name}</span>
                    </h6>
                    <div className="GameListItemWrap">
                      <ul>
                        <li>
                          <small>ENTRY FEE</small>
                          <p>
                            <i>
                              <Icon icon="fluent-emoji:money-with-wings" />
                            </i>
                            {game.amount}
                          </p>
                        </li>
                        <li>
                          <small>PRIZE</small>
                          <p>
                            <i>
                              <Icon icon="fluent-emoji:money-with-wings" />
                            </i>
                            {game.received_amount}
                          </p>
                        </li>
                      </ul>
                      <Link
                        to="#"
                        className="btn btn-gray"
                        onClick={() => onAddBettleRequest(game.amount, gameId)}
                      >
                        Play
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <hr />
          <div className="GameListMain">
            <div className="GameListHeader">
              <h6>
                <span>
                  <Image src="/image/talvar.png" />
                </span>
                Running Battles
              </h6>
            </div>
            {allRunningGames.map((game, index) => (
              <div className="GameListBody" key={index}>
                <div className="GameListItem">
                  <div className="GameRunningHeader">
                    <h6>
                      PLAYING FOR
                      <i>
                        <Icon icon="fluent-emoji:money-with-wings" />
                      </i>
                      {game.amount}
                    </h6>
                    {game.show_view_btn ? 
                    game.status === 2 ? (
                      <Link
                        to="/roomcode"
                        state={{ gameId: game._id }}
                        className="btn btn-red "
                      >
                        View
                      </Link>
                    ) :
                      <Link
                        to="/roomcode"
                        state={{ gameId: game._id }}
                        className="btn btn-green"
                      >
                        View
                      </Link> : (
                      <h6 className="">
                        PRIZE <Icon icon="fluent-emoji:money-with-wings" />
                        {game.received_amount}
                      </h6>
                    )}
                  </div>
                  <div className="GameRunningBody">
                    <div className="GameProfile">
                      <span>
                        <Image src={game.from_image} />
                      </span>
                      <p>{game.from_name}</p>
                    </div>
                    <i>
                      <Image src="/image/verses.png" />
                    </i>
                    <div className="GameProfile">
                      <span>
                        <Image src={game.to_image} />
                      </span>
                      <p>{game.to_name}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default GameList;
