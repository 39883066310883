import { Button, Form, Image } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import Sidebar from '../../Component/Sidebar/Sidebar';
import MainHeader from '../../Component/MainHeader/MainHeader';
import './UpiWithDraw.css';
import { onWithdrawal } from '../WithDrawPage/action';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { onGetUserProfile } from '../ProfilePage/action';
import { ongetSetting } from '../EarningPage/action';

function UpiWithDraw() {
  const { amount } = useParams();
  const [toggleMenu, setToggleMenu] = useState(false)
  const [userviewInfo, setUserviewInfo] = useState({});
  const [showDiv, setShowDiv] = useState(false);
  const navigate = useNavigate();
  const [Setting, setSetting] = useState({});
  const [timeLeft, setTimeLeft] = useState(null);
  const [UPIData, setUPIData] = useState({
    amount: amount,
    upi: "",
    confirm_upi: ""
  });

  useEffect(() => {
    getUserProfile();
    getStatus();
    if (showDiv) {
      const redirectTimeout = setTimeout(() => {
        navigate("/");
      }, 1000);

      return () => {
        clearTimeout(redirectTimeout);
      };
    }
  }, [showDiv]);
  useEffect(() => {
    if(timeLeft===0){
      //  console.log("TIME LEFT IS 0");
       setTimeLeft(null)
    }

    // exit early when we reach 0
    if (!timeLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeLeft]);
  const handleClick = () => {
    setToggleMenu(!toggleMenu)
  }
  const getUserProfile = () => {
    onGetUserProfile().then((response) => {
      if (response?.data?.IsSuccess) {
        setUserviewInfo(response.data);
      }
    });
  };
  function onHandleChange({ target }) {
    const { name, value } = target;

    setUPIData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  const onHandleUPI = (e) => {
    e.preventDefault();
    if (!UPIData.amount || !UPIData.confirm_upi || !UPIData.upi) {
      toast.error('Please Enter the values.');
    }
    else if (UPIData.confirm_upi !== UPIData.upi) {
      toast.error('UPI ID do not match');
    }
    else {
      const data = {
        amount: UPIData.amount,
        payment_method: "UPI",
        upi: UPIData.upi,
      };
      onWithdrawal(data).then((resp) => {
        if (resp.data.IsSuccess) {
          setUPIData(resp.data);
          toast.success(resp.data.Message);
          setShowDiv(true);
          setTimeLeft(1);
        } else {
          toast.error(resp.data.Message);
        }
      });
    }
  }
  const getStatus = () => {
    ongetSetting().then((response) => {
      if (response?.data?.IsSuccess) {
        setSetting(response.data.Data.withdraw);
      }
    });
  };
  return (
    <>
      <div className={`MainScreen  ${toggleMenu ? 'show' : ''}`}>
        <MainHeader handleClick={handleClick} />
        <hr />
        <Sidebar />
        <div className='AddCashBody'>
          <h4>Withdraw</h4>
          <Form onSubmit={onHandleUPI}>
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <Form.Label>Enter Amount</Form.Label>
              <Form.Control type="number" placeholder="Enter ₹"
                // onChange={onHandleChange}
                value={UPIData.amount}
                name="amount" />
              <Form.Text className="">
                Min : {Setting.min}, Max: {Setting.max}
              </Form.Text>
            </Form.Group>
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <Form.Label>Withdraw through UPI</Form.Label>
              <Form.Control type="text" placeholder="Enter UPI ID" onChange={onHandleChange}
                value={UPIData.upi}
                name="upi" />
            </Form.Group>
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <Form.Label>Re-Enter Withdraw through UPI</Form.Label>
              <Form.Control type="text" placeholder="Re-Enter UPI ID" onChange={onHandleChange}
                value={UPIData.confirm_upi}
                name="confirm_upi" />
            </Form.Group>
            {showDiv && (
              <div className='upiBox'>
                <h6><span><Image src='/image/UIPIcon.svg' /></span>{UPIData.upi}</h6>
                <i><Icon icon="mdi:tick-decagram" color="#25B4A1" /></i>
                <p>Payment Withdrawal Request Success <br /> <small>Redirect in {timeLeft} Sec</small></p>
              </div>)}
            <hr />
            <Button className='btn btn-green d-block w-100 text-center mt-5' type='submit'  disabled={timeLeft > 0}>SUBMIT</Button>
          </Form>
        </div>
      </div>
    </>
  );
}

export default UpiWithDraw;
