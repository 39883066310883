import Axios from "../../AxiosApi";

export const onWithdrawal = (storeData) => {
  return Axios({
    method: "post",
    url: `transactions/store-withdrawal-request`,
    data: storeData
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};