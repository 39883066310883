import Axios from "../../AxiosApi";

export const getAllGames = () => {
  return Axios({
    method: "get",
    url: `games/list`,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};


