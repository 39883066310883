import { Image, Pagination } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import Sidebar from '../../Component/Sidebar/Sidebar';
import MainHeader from '../../Component/MainHeader/MainHeader';
import './ReferralHistory.css';
import moment from 'moment';
import { onReferHistory } from './action';

function ReferralHistory() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [gamelist, setGamelist] = useState([]);
  const initLimit = "20";
  const [page, setPage] = useState("1");
  const [totalPage, setTotalPage] = useState(0);
  const handleClick = () => {
    setToggleMenu(!toggleMenu);
  };

  useEffect(() => {
    const data = {
      limit: initLimit,
      page_number: page,
    };
    getAllTransactionList(data);
  }, [0]);

  const getAllTransactionList = (data) => {
    onReferHistory(data).then((resp) => {
      if (resp.data.IsSuccess) {
        const totalPage = resp.data.totalRecords / Number(data.limit);
        if (totalPage < 1) {
          setTotalPage(1);
        } else {
          setTotalPage(Math.ceil(totalPage));
        }
        setGamelist(resp.data.Data);
      }
    });
  };

  const onChangePage = (pageNumber) => {
    if (page !== pageNumber.toString()) {
      setPage(pageNumber.toString());
      const data = {
        limit: initLimit,
        page_number: pageNumber.toString(),
      };
      getAllTransactionList(data);
    }
  };

  const showPagination = () => {
    let items = [];
    for (let number = 1; number <= totalPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === Number(page)}
          onClick={() => onChangePage(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    return items;
  };

  return (
    <>
      <div className={`MainScreen  ${toggleMenu ? "show" : ""}`}>
        <MainHeader handleClick={handleClick} />
        <hr />
        <Sidebar />
        {gamelist?.length > 0 ? (
          <div className="GameHistoryBody">
            <Pagination size="sm">{showPagination()}</Pagination>
            {gamelist?.map((game, index) => (
              <div key={index}>
                <div className="GameHistoryItem">
                  <div className="GameHistoryTime">
                    <h6>{moment(game.created_date_time).format("MMM Do")}</h6>
                    <small>{moment(game.created_date_time).format("LT")}</small>
                  </div>
                  <div className="GameHistoryInfo">
                    <span>
                      <Image src={game.game_image} />
                    </span>
                    <h6>
                      Referral Earning
                      <small>{game.payment_id}</small>
                      <small>Earned By: {
                        game.to_user_name
                      }</small>
                    </h6>
                  </div>
                  <div className="GameHistoryCash">
                    <p>
                      <Icon icon="fluent-emoji:money-with-wings" />
                      {game.amount}
                    </p>
                    <small>Closing Balance: {game.referral_balance}</small>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className='NotificationPageBody'>
            <Image className='mx-50' src="/image/refer.png" />
            <h6 className="text-white">No Referrals yet!</h6>
            <p className="text-white">
              Seems like you haven't done any activity yet
            </p>
          </div>
        )}
      </div>
    </>
  );
}

export default ReferralHistory;
