import { Alert, Button, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import Sidebar from '../../Component/Sidebar/Sidebar';
import MainHeader from '../../Component/MainHeader/MainHeader';
import './EarningPage.css';
import { onCheckStatus, ongetSetting, onStoreRedeem } from './action';
import { toast } from 'react-toastify';

function EarningPage() {
  const [toggleMenu, setToggleMenu] = useState(false)
  const [Status, setStatus] = useState({});
  const [Setting, setSetting] = useState({});
  const [Redeem, setRedeem] = useState("");
  const [Redeemdata, setRedeemdata] = useState({});
  const navigate = useNavigate();

  const handleClick = () => {
    setToggleMenu(!toggleMenu)
  }

  useEffect(() => {
    getStatus();
  }, [0]);

  const getStatus = () => {
    ongetSetting().then((response) => {
      if (response?.data?.IsSuccess && response.data.Data.pancard_verification == "yes") {
        onCheckStatus().then((response) => {
          if (response?.data?.IsSuccess) {
            setStatus(response.data);
            // console.log(setStatus(response.data))
          }
        });
      }
      setSetting(response.data.Data)

    });
  };
  const handleChange = (event) => {
    setRedeem(event.target.value);
  };
  // console.log({  Setting })
  const onRedeem = () => {
    const data = {
      amount: Redeem
    }
    // console.log({ data, Setting })
    if (Setting.pancard_verification === "yes") {
      if (Redeem === "") {
        toast.error("Please Enter an Amount.")
      }
      else if (Status.is_uploaded === 0) {
        navigate('/pancard');
      }
      else if (Status.is_uploaded === 1) {
        toast.warn("Your PAN card is in pending status.Please wait for Admin  Approval.");
      } else if (Status.is_uploaded === 2) {
        onStoreRedeem(data).then((resp) => {
          if (resp.data.success) {
            setRedeem(resp.data);
            toast.success(resp.data.Message);
            setTimeout(() => {
              navigate('/');
            }, 2500)
          } else {
            toast.error(resp.data.Message)
          }
        });
      } else if (Status.is_uploaded === 3) {
        toast.error("Your PAN card is Rejected.Please re-upload your document.");
        setTimeout(() => {
          navigate('/pancard');
        }, 2500);
      }

    } else if (Setting.pancard_verification === "no") {
      if (Redeem === "") {
        toast.error("Please Enter an Amount.")
      }
      else {
        // console.log('CALL')
        onStoreRedeem(data).then((resp) => {
          if (resp.data.success) {
            setRedeem(resp.data);
            toast.success(resp.data.Message);
            // console.log(setRedeemdata(resp.data))
            setTimeout(() => {
              navigate('/');
            }, 2500)
          } else {
            toast.error(resp.data.Message)
          }
        });
      }
    }
  }
  const handlePancard = (e) => {
    e.preventDefault()
    if (Status.is_uploaded === 0) {
      toast.error("Please Enter PAN card Detials")
      navigate('/pancard');
    }
    else if (Status.is_uploaded === 1) {
      toast.warn("Your PAN card is in pending status.Please wait for Admin  Approval.");
    } else if (Status.is_uploaded === 2) {
      toast.success("Your PAN card is completed.");
    } else if (Status.is_uploaded === 3) {
      toast.error("Your PAN card is Rejected.Please re-upload your document.");
      setTimeout(() => {
        navigate('/pancard');
      }, 2500);
    }
  }
  return (
    <>
      <div className={`MainScreen  ${toggleMenu ? 'show' : ''}`}>
        <MainHeader handleClick={handleClick} />
        <hr />
        <Sidebar />
        <div className='EarningPageBody'>
          {Setting.pancard_verification === "yes" || !Status.is_uploaded === 2 ?
            (<Link onClick={handlePancard}>
              <Alert variant="danger">
                <Icon icon="jam:triangle-danger-f" color="red" />Update your pan card to redeem balance.
              </Alert>
            </Link>) : ""}
          <h6>Redeem your refer balance</h6>
          <p>TDS (5%) will be deducted after annual referral earning of ₹0.</p>
          <Form.Group >
            <Form.Label>Enter Amount (Min: 100, Max: 10000)</Form.Label>
            <Form.Control type="text" placeholder="" onChange={handleChange} value={Redeem} />
            <Form.Text className="">
              Money will be added to RKLudo cash.
            </Form.Text>
          </Form.Group>
          {/* {Status.is_uploaded === 2 ? */}
          <Button onClick={onRedeem} className='btn btn-green d-block text-center w-100 mt-5' disabled={!(Redeem >= 100 && Redeem <= 10000)}>REDEEM</Button>
          {/* : ''} */}
        </div>
      </div>
    </>
  );
}

export default EarningPage;
