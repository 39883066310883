import Axios from "../../AxiosApi";

export const onGetRoomDetails = (game) => {
  return Axios({
    method: "post",
    url: `game/room-detail`,
    data: game
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const onUploadResult = (game) => {
  return Axios({
    method: "post",
    url: `game/upload-result`,
    data: game
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const onCancelRunningGame = (game) => {
  return Axios({
    method: "post",
    url: `game/cancelled-running-game`,
    data: game
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};