import Axios from "../../AxiosApi";

export const checkUserKyc = () => {
  return Axios({
    method: "get",
    url: `profile/check-kyc`,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const getAvatarList = () => {
  return Axios({
    method: "get",
    url: `profile/get-avatar`,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

export const onUpdateProfile = (profileData) => {
  return Axios({
    method: "post",
    url: `profile/store`,
    data: profileData
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const onTranscation = (transactionData) => {
  return Axios({
    method: "post",
    url: `transactions/list`,
    data: transactionData
  })
    .then((response) => {
      // console.log("success",transactionData);
      return response;
    })
    .catch((err) => {
      // console.log("error", transactionData);
      return err.response;
    });
};

