import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from '@iconify/react';
import './Sidebar.css';
import { useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { onhandleLogout } from "../../Pages/ProfilePage/action";

function Sidebar() {
  const navigate = useNavigate();
  const [logoutClick, setlogoutClick] = useState(false);
  const handlelogoutClick = () => {
    setlogoutClick(!logoutClick);
  };
  const handleCancel = () => {
    setlogoutClick(false);
  };

  const handleConfirm = () => {
    onhandleLogout().then((resp) => {
      if (resp.data.IsSuccess) {
        localStorage.removeItem("token");
        toast.success("Logout Successfully");
        setTimeout(() => {
          navigate("/login");
        }, 1500);
      } else {
        toast.error(resp.data.Message);
      }
    });
  };
  return (
    <ul className='SideMenu'>
      <li><Link to="/profile"><span><Icon icon="mdi:user-circle" /></span> My Profile</Link></li>
      <li><Link to="/"><span><Icon icon="ph:game-controller-fill" /></span> Win Cash</Link></li>
      <li><Link to="/wallet"><span><Icon icon="material-symbols:account-balance-wallet" /></span> My Wallet</Link></li>
      <li><Link to="/game-history"><span><Icon icon="ion:trophy" /></span> Games History</Link></li>
      <li><Link to="/transactions"><span><Icon icon="ic:baseline-history" /></span> Transaction History</Link></li>
      <li><Link to="/withraw-request"><span><Icon icon="uil:money-withdraw" /></span> Withdraw Request</Link></li>
      <li><Link to="/referral-history"><span><Icon icon="material-symbols:group-add" /></span> Refer History</Link></li>
      <li><Link to="/refer-earn"><span><Icon icon="fluent-mdl2:add-friend" /></span> Refer & Earn</Link></li>
      <li><Link to="/notification"><span><Icon icon="clarity:notification-solid" /></span> Notification</Link></li>
      <li><Link to="/support"><span><Icon icon="fluent:person-support-16-filled" /></span> Support</Link></li>
      <li><Link to="/LegalTerm"><span><Icon icon="dashicons:text-page" /></span> Legal Terms</Link></li>
      {/* <li><Link onClick={handlelogoutClick}><span> <Icon icon="uiw:logout" /></span> Logout</Link></li> */}

      {/* <div div className={`Popupp  ${logoutClick ? "show blur-background" : ""}`}>
        <div className="box">
          <h1 className="text-center"><Icon icon="mdi:question-mark-circle" fontSize={56} color="#25B4A1" /></h1>
          <p className="text-dark text-center">Do You Want To Logout?</p>
          <Button className='btn btn-border-green ' style={{ margin: "auto 10px ", padding: "8px 28px" }} onClick={handleCancel}>Cancel</Button>
          <Button className='btn btn-green px-5 mx-1 my-1' onClick={handleConfirm}>Ok</Button>

        </div>
      </div> */}
    </ul>

  );
}

export default Sidebar;
