import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

const ReferCode = () => {
    const { referralCode } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        localStorage.setItem('referralCode', referralCode);
        navigate('/');
    }, [referralCode])
}
export default ReferCode;