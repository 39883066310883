import { Button, Col, Form, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Sidebar from '../../Component/Sidebar/Sidebar';
import MainHeader from '../../Component/MainHeader/MainHeader';
import './AddCashPage.css';
import { onGetUserProfile } from '../ProfilePage/action';
import { ongetSetting } from '../EarningPage/action';
import { useNavigate } from 'react-router-dom';

function AddCashPage() {

  const [toggleMenu, setToggleMenu] = useState(false)
  const [amount, setamount] = useState('');
  const [profile, setprofile] = useState({});
  const [Setting, setSetting] = useState({});
  const navigate = useNavigate();

  const handleClick = () => {
    setToggleMenu(!toggleMenu)
  }

  useEffect(() => {
    getUserProfile();
    getStatus();
  }, [0]);

  const navigateto = (e) => {
    e.preventDefault()
    navigate(`/paymentmethods/${amount}`,{state:{amount: amount}});
  }
  const onHandleChange = (event) => {
    const value = event.target.value;
    setamount(value);
  };


  const handleButtonClick = (value) => {
    setamount(value);
  };

  const getUserProfile = () => {
    onGetUserProfile().then((response) => {
      // console.log({ response });
      if (response?.data?.IsSuccess) {
        setprofile(response?.data);
      }
    });
  };
  const getStatus = () => {
    ongetSetting().then((response) => {
      if (response?.data?.IsSuccess) {
        setSetting(response.data.Data.deposit);
      }
    });
  };
  return (
    <>
      <div className={`MainScreen  ${toggleMenu ? 'show' : ''}`}>
        <MainHeader handleClick={handleClick} />
        <hr />
        <Sidebar />
        <div className='AddCashBody'>
          <h4>Choose amount to add</h4>
          <Form onSubmit={navigateto}>
            <Form.Group className="mb-4" controlId="formBasicEmail">
              <Form.Label>Enter Amount</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter ₹"
                value={amount}
                onChange={onHandleChange} />
              <Form.Text className="">
                Min : {Setting.min}, Max: {Setting.max}
              </Form.Text>
            </Form.Group>
            <Row>
              <Col lg={6} md={6} sm={6} xs={6} >
                <label className="customPriceBox" htmlFor="Price01" >
                  <input id="Price01" type="radio" name='PriceRadio' onClick={() => handleButtonClick('100')} />
                  <p className='btn-white'>₹100</p>
                </label>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6} >
                <label className="customPriceBox" htmlFor="Price02">
                  <input id="Price02" type="radio" name='PriceRadio' onClick={() => handleButtonClick('250')} />
                  <p className='btn-white'>₹250</p>
                </label>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6} >
                <label className="customPriceBox" htmlFor="Price03">
                  <input id="Price03" type="radio" name='PriceRadio' onClick={() => handleButtonClick('500')} />
                  <p className='btn-white'>₹500</p>
                </label>
              </Col>
              <Col lg={6} md={6} sm={6} xs={6} >
                <label className="customPriceBox" htmlFor="Price04">
                  <input id="Price04" type="radio" name='PriceRadio' onClick={() => handleButtonClick('1000')} />
                  <p className='btn-white'>₹1000</p>
                </label>
              </Col>
            </Row>

            <hr />
            {/* <div className='CashbackBox'>
              <h6>CASHBACK OFFER!</h6>
              <p>Mobikwik Wallet (Upto Rs. 750)</p>
              <ul>
                <li>Use Code: <strong>MBK750</strong> On The MobiKwik Payment Page To Avail Per Offer</li>
                <li>Offer Valid Twice Per User In A Month</li>
                <li>Offer Valid On A Minimum Transaction Of Rs.1999</li>
              </ul>
            </div> */}
            <Button className='btn btn-green d-block w-100 text-center' type="submit" disabled={!(amount >= parseInt(Setting.min) && amount <= parseInt(Setting.max))}>NEXT</Button>

          </Form>
        </div>
      </div>
    </>
  );
}

export default AddCashPage;
