import { Accordion, Alert, Image } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Sidebar from '../../Component/Sidebar/Sidebar';
import MainHeader from '../../Component/MainHeader/MainHeader';
import { onAddCash, ongetCashfree } from "./action";
import './AddCashPage.css';
import { onGetUserProfile } from '../ProfilePage/action';
import { toast } from "react-toastify";
import { ongetSetting } from '../EarningPage/action';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { load } from '@cashfreepayments/cashfree-js';


function PaymentMethods() {
    
    const { amount } = useParams();
    const [toggleMenu, setToggleMenu] = useState(false)
    const [AmountData, setAmountData] = useState([])
    const [cashfreeData, setcashfreeData] = useState([])
    const [profile, setprofile] = useState({});
    const [Setting, setSetting] = useState({});
    const navigate = useNavigate();


    const gettokencashfree = () => {
        const values = {
            amount: amount,
        }
        ongetCashfree(values).then((resp) => {
            if (resp.data.IsSuccess) {
                setcashfreeData(resp.data.Data);
                // console.log(resp.data.Data)
                toast.success(resp.data.Message);
            } else {
                toast.error(resp.data.Message);
            }
        })
    }
    async function opencashfree(e) {
        e.preventDefault()
        const cashfree = await load({
            mode: "production",
        });
        let checkoutOptions = {
            amount: amount * 100,
            paymentSessionId: cashfreeData.payment_session_id,
            returnUrl:
                "https://rkludo.com/addcash",
                theme: { color: '#25b4A1' }
        };
        if (!profile.email) {
            toast.error("Please Enter The Profile Email.")
            setTimeout(() => {
                navigate('/profile');
            }, 2500);
        } else {
            cashfree.checkout(checkoutOptions).then(function (result) {
                if (result.error) {
                    toast.error(result.error.message);
                }
                if (result.redirect) {
                    // toast.success("Redirect")         
                    // console.log("Redirect")
                }
            });
        }
    }

    const handleClick = () => {
        setToggleMenu(!toggleMenu)
    }
    const options = {
        key: 'rzp_live_iMquRoE6Un8Z0x',
        amount: amount * 100,
        image: 'https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png',
        handler: function (response) {
            alert(response.razorpay_payment_id);
            const value = {
                payment_id: response.razorpay_payment_id,
                amount: amount,
                payment_method: "Razorpay"
            }

            onAddCash(value).then((resp) => {
                if (resp.data.IsSuccess) {
                    setAmountData(resp.data);
                    toast.success(resp.data.Message);
                } else {
                    // toast.error(resp.data.Message);
                }
            })
        },
        modal: {
            "ondismiss": function () {
                toast.error("Payment Failed")
            }
        },
        theme: { color: '#25b4A1' }
    };

    const openPayModal = (e) => {
        e.preventDefault();
        if (!profile.email) {
            toast.error("Please Enter The Profile Email.")
            setTimeout(() => {
                navigate('/profile');
            }, 2500);
        } else {
            var rzp1 = new window.Razorpay(options);
            rzp1.open();
        }
    };
    const getUserProfile = () => {
        onGetUserProfile().then((response) => {
            // console.log({ response });
            if (response?.data?.IsSuccess) {
                setprofile(response?.data);
            }
        });
    };
    const getStatus = () => {
        ongetSetting().then((response) => {
            if (response?.data?.IsSuccess) {
                setSetting(response.data.Data.deposit);
            }
        });
    };
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        // script.src = 'https://sdk.cashfree.com/js/ui/2.0.0/cashfree.sandbox.js';
        script.async = true;
        document.body.appendChild(script);
        getUserProfile();
        getStatus();
        gettokencashfree();
    }, [0]);
 
    return (
        <>
            <div className={`MainScreen  ${toggleMenu ? 'show' : ''}`}>
                <MainHeader handleClick={handleClick} />
                <hr />
                <Sidebar />
                <div className='AddCashBody'>
                    <Link onClick={opencashfree}>
                        <Alert variant="secondary">
                            <Image src="/image/img_depos.png" style={{ width: "32px", height: "32px" }} /><span>Deposit 1</span>
                        </Alert>
                    </Link>
                    <Link onClick={openPayModal}>
                        <Alert variant="secondary">
                            <Image src="/image/img_card.png" /><span>Deposit 2</span>
                        </Alert>
                    </Link>
                    {/* <Link onClick={handlePayment}>
                        <Alert variant="secondary">
                            <Image src="/image/img_card.png" /><span>Phone Pay</span>
                        </Alert>
                    </Link> */}
                </div>
            </div>
        </>
    );
}

export default PaymentMethods;
