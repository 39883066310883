import Axios from "../../AxiosApi";

export const onHandleLogin = (loginData) => {
  return Axios({
    method: "post",
    url: `kk-login`,
    data: loginData,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const onHandleRegistration = (loginData) => {
  return Axios({
    method: "post",
    url: `kk-register`,
    data: loginData,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const onVerifyOtp = (data) => {
  return Axios({
    method: "post",
    url: `kk-validate-otp`,
    data: data,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
