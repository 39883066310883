import { Image, Pagination } from "react-bootstrap";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import MainHeader from "../../Component/MainHeader/MainHeader";
import { onGameHistory } from "./action";
import "./GameHistory.css";
import moment from "moment";

function GameHistory() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [GameHistorylist, setGameHistorylist] = useState([]);
  const initLimit = 20;
  const [page, setPage] = useState("1");
  const [totalPage, setTotalPage] = useState(0);
  const handleClick = () => {
    setToggleMenu(!toggleMenu);
  };

  useEffect(() => {
    const data = {
      limit: initLimit,
      page_number: page,
    };
    getAllGameHistory(data);
  }, [0]);

  const getAllGameHistory = (data) => {
    onGameHistory(data).then((resp) => {
      if (resp.data.IsSuccess) {
        const totalPage = resp.data.totalRecords / Number(data.limit);

        // console.log({ totalPage });
        if (totalPage < 1) {
          setTotalPage(1);
        } else {
          setTotalPage(Math.ceil(totalPage));
        }
        // console.log({ data: resp.data });
        setGameHistorylist(resp.data.Data);
      }
    });
  };

  const onChangePage = (pageNumber) => {
    if (page !== pageNumber.toString()) {
      setPage(pageNumber.toString());
      const data = {
        limit: initLimit,
        page_number: pageNumber.toString(),
      };
      getAllGameHistory(data);
    }
  };


  const showPagination = () => {
    let items = [];
    for (let number = 1; number <= totalPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === Number(page)}
          onClick={() => onChangePage(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    return items;
  };
  const formattedDate = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: 'numeric'
  }).format(GameHistorylist.created_date);

  const formattedTime = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    timeZone: 'UTC',
    minute: '2-digit'
  }).format(GameHistorylist.created_time);
  return (
    <>
      <div className={`MainScreen  ${toggleMenu ? "show" : ""}`}>
        <MainHeader handleClick={handleClick} />
        <hr />
        <Sidebar />
        {GameHistorylist?.length > 0 ? (
          <div className="GameHistoryBody">
            <Pagination size="sm">{showPagination()}</Pagination>
            {GameHistorylist?.map((game, index) => (
              <div key={index}>
                {game.status === 3 ? (
                  <div className="GameHistoryItem">
                    <div className="GameHistoryTime">
                      <h6>{moment(game.created_date_time).format("MMM Do")}</h6>
                      <small>{moment(game.created_date_time).format("LT")}</small>
                    </div>
                    <div className="GameHistoryInfo">
                      <span>
                        <Image src={game.game_image} />
                      </span>
                      <h6>Cancel against {game.to_user_name}
                        <small>Battel ID: {game.room_code}</small></h6>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {game.status === 4 ? (
                  <div className="GameHistoryItem">
                    <div className="GameHistoryTime">
                    <h6>{moment(game.created_date_time).format("MMM Do")}</h6>
                      <small>{moment(game.created_date_time).format("LT")}</small>
                    </div>
                    <div className="GameHistoryInfo">
                      <span>
                        <Image src={game.game_image} />
                      </span>
                      <h6>{game.to_user_name === game.winner_name ? `Lost against ${game.to_user_name}` : `Won against ${game.to_user_name}`}<small>Battel ID: {game.room_code}</small></h6>
                      
                    </div>
                    <div className="GameHistoryCash">
                      <p>
                        {game.to_user_name === game.winner_name ? (
                          <span className="red">(-)</span>
                        ) : (
                          <span className="green">(+)</span>
                        )}

                        <Icon icon="fluent-emoji:money-with-wings" />
                        {game.to_user_name === game.winner_name ? `${game.amount}` : `${game.received_amount}`}
                      </p>
                      <small>{game.to_user_name === game.winner_name ? `Closing Balance ${game.loser_wallet_balance
                        }` : `Closing Balance: ${game.winner_wallet_balance}`}</small>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
          </div>
        ) : (
          <div className="NotificationPageBody">
            <Image src="/image/transactions.png" />
            <h6 className="text-white">No History yet!</h6>
            <p className="text-white">
              Seems like you haven't done any activity yet
            </p>
          </div>
        )}
      </div>
    </>
  );
}

export default GameHistory;
