import { Button, Form } from 'react-bootstrap';
import './WizardStep3.css';

const WizardStep3 = ({onHandleChange, onSubmitKyc, kycData}) => {

  return (
    <>
     <div className='KycFrom'>
        <h4>KYC Verification</h4>
        <p>Step 3 of 3</p>
        <small>Enter details of AadharCard: <span>{kycData?.aadhar_number}</span> <br/><br/> Ensure that your Name, Birthdate and Document Number are clearly visible in the document photo.</small>
        <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Upload Front Photo</Form.Label>
              <Form.Control type="file" name='front_photo' onChange={onHandleChange}/>
            </Form.Group> 
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Upload Back Photo</Form.Label>
              <Form.Control type="file"  name='back_photo' onChange={onHandleChange}/>
            </Form.Group> 
        </Form>
     </div>
     <Button className='btn btn-green mt-5 d-block w-100' variant='' disabled={kycData?.fron_photo === '' || kycData.back_photo === ''} onClick={onSubmitKyc}>Complete</Button>
    </>
  );
}

export default WizardStep3;
