import { Button, Form, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import Sidebar from "../../Component/Sidebar/Sidebar";
import MainHeader from "../../Component/MainHeader/MainHeader";
import "./RoomCode.css";
import { onCancelRunningGame, onGetRoomDetails, onUploadResult } from "./action";
import { getAllGames } from "../DashBoardPage/action";

function RoomCode() {

  const [toggleMenu, setToggleMenu] = useState(false);
  const [gameId, setGameId] = useState({});
  const [roomDetails, setRoomDetails] = useState({});
  const [result, setResult] = useState("");
  const [screenShot, setScreenShot] = useState("");
  const [reason, setReason] = useState("");
  const [uploadedImageURL, setUploadedImageURL] = useState("");
  const [urlBtnText, setUrlBtnText] = useState("Copy"); 
  const [gamelist, setGamelist] = useState({})
  const location = useLocation();
  const navigate = useNavigate();


  useEffect(() => {
    if (location?.state?.gameId) {
      setGameId(location.state.gameId);
    } else {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(gameId)) {
      const data = {
        id: gameId,
      };
      onGetRoomDetails(data).then((resp) => {
        if (resp.data.IsSuccess) {
          setRoomDetails(resp.data.Data);
        }
      });
    }
  }, [gameId]);

  const handleClick = () => {
    setToggleMenu(!toggleMenu);
  };

  const handleResultChange = (e) => {
    setResult(e.target.value);
  };

  const uploadResult = () => {
    let resultData = {};
    if (result === "won") {
      var form_data = new FormData();
      form_data.append("id", roomDetails._id);
      form_data.append("image", screenShot);
      form_data.append("win_type", 1);
      resultData = form_data;
    } else if (result === "lost") {
      resultData = {
        id: roomDetails._id,
        note: "I LOST",
        win_type: 2,
      };
      // console.log("lost");
    } else {
      // console.log({ roomDetails })
      resultData = {
        id: roomDetails._id,
        note: "I CANCEL",
        reason: reason
      };
    }
    if (result === "cancel") {
      onCancelRunningGame(resultData).then((resp) => {
        // console.log({ respCancel: resp })
        if (resp.data.IsSuccess) {
          navigate("/");
        }
      });
    } else {
      onUploadResult(resultData).then((resp) => {
        if (resp.data.IsSuccess) {
          navigate("/");
        }
      });
    }
  };

  const handleUploadScreenShot = (event) => {
    setScreenShot(event.target.files[0]);
    setUploadedImageURL(URL.createObjectURL(event.target.files[0]));
  };
  return (
    <>
      <div className={`MainScreen  ${toggleMenu ? "show" : ""}`}>
        <MainHeader handleClick={handleClick} />
        <hr />
        <Sidebar />
        <div className="RoomCodeBody">
          <div className="RoomCodePlayeriNfo">
            <div className="GameRunningBody">
              <div className="GameProfile">
                <span>
                  <Image src={roomDetails?.from_image} />
                </span>
                <p>{roomDetails?.from_name}</p>
              </div>
              <i>
                <Image src="/image/verses.png" />
              </i>
              <div className="GameProfile">
                <span>
                  <Image src={roomDetails?.to_image} />
                </span>
                <p>{roomDetails?.to_name}</p>
              </div>
            </div>
            <p>
              <i>
                {/* <Icon icon="fluent-emoji:money-with-wings" /> */}
              </i>{" "}
              PLAYING FOR  <Icon icon="fluent-emoji:money-with-wings" />{roomDetails?.amount}
            </p>
          </div>
          <hr />
          <div className="RoomCodeBox">
            <h6>Room Code</h6>
            <h3>{roomDetails?.room_code}</h3>
            <Link
              to="#"
              className="btn btn-green"
              onClick={() => {
                setUrlBtnText("Copied");
                setTimeout(() => {
                  setUrlBtnText("Copy");
                }, 2000);
                navigator.clipboard.writeText(roomDetails?.room_code);
              }}
            >
              {urlBtnText}
            </Link>
          </div>
          <hr />
          <div className="GameRulesBox">
            <h6>Game Rules</h6>
            <ul>
              <li>Record every game while playing.</li>
              <li>For Cancellation of game, video proof is necessary</li>
              <li style={{ color: "indianred" }}>
                <i>
                  <Icon icon="fluent-emoji:money-with-wings" />
                </i>{" "}
                100 Fraud/ Fake Screenshot
              </li>
              <li>
                <i>
                  <Icon icon="fluent-emoji:money-with-wings" />
                </i>{" "}
                50 Wrong Update
              </li>
              <li>
                <i>
                  <Icon icon="fluent-emoji:money-with-wings" />
                </i>{" "}
                50 No Update
              </li>
              <li>
                <i>
                  <Icon icon="fluent-emoji:money-with-wings" />
                </i>{" "}
                50 Abusing
              </li>
              <li>In Popular, if a user plays from Facebook, then that user's game will be considered cancelled.</li>
            </ul>
          </div>
          <hr />
          {/* {console.log({ result_type: roomDetails.result_type })} */}

          {roomDetails.result_type === 0 ? (
            <>
              <div className="matchStatus">
                <h6>Match Status</h6>
                <p>
                  After Completion of your game, select the status of the game
                  and post your screenshot below.
                </p>
                <div className="radioFlex">
                  <Form.Check
                    type="radio"
                    name="result"
                    label="I WON"
                    value="won"
                    onChange={handleResultChange}
                  />
                  <Form.Check
                    type="radio"
                    name="result"
                    label="I LOST"
                    value="lost"
                    onChange={handleResultChange}
                  />
                  <Form.Check
                    type="radio"
                    name="result"
                    label="CANCEL"
                    value="cancel"
                    onChange={handleResultChange}
                  />
                </div>
              </div>
              <div className="RoomCodeFlexWrap">
                {result === "won" && (
                  <>
                    <input type="file" onChange={handleUploadScreenShot} />
                    <div>{uploadedImageURL && <Image src={uploadedImageURL} />}</div>
                  </>
                )}
                {result === "cancel" && (
                  <textarea
                    rows="5"
                    placeholder="Enter Cancel Reason"
                    onChange={(e) => setReason(e.target.value)}
                  />
                )}
                <Button
                  className="btn btn-green mt-4"
                  variant=""
                  disabled={
                    result === "" ||
                    (result === "won" && !screenShot) ||
                    (result === "cancel" && !reason)
                  }
                  onClick={uploadResult}
                >
                  POST RESULT
                </Button>
              </div>
            </>
          ) : (
            <p className="text-success">
              You have already updated your battle result for{" "}
              {roomDetails.result_type === 1
                ? "WON"
                : roomDetails.result_type === 2
                  ? "LOST"
                  : roomDetails.result_type === 3                  
                  ? "CANCEL" : ""}
            </p>
          )}
        </div>
      </div>
    </>
  );
}

export default RoomCode;
