import axios from "axios";

const API_URL = process.env.REACT_APP_API_BASE_URL
const Token = `Bearer ${localStorage.getItem('token')}`

console.log(Token, "Token from Axios");

const Axios = axios.create({
    baseURL: API_URL,
});

export const setAuthHeader = (token) => {
    Axios.defaults.headers.common.Authorization =
        token || Token;
};

Axios.defaults.headers.common.Authorization = Token

//if a 401 happens, the user will be logged out
Axios.interceptors.response.use(
    function (response) {
        // console.log({ response })
        if(response && response.data && response.data.ErrorCode === 401) {
            localStorage.removeItem("token");
        }
        return response;
        // console.log({ response })
    },
    function (error) {
        // console.log({ error })
        if (error && error.response && error.response.status === 401) {
            localStorage.removeItem("token");
        }
        return Promise.reject(error);
    }
);

// Set the initial header from storage or something (should surround with try catch in actual app)
setAuthHeader(Token);

export default Axios;
