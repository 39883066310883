import { useEffect, useState } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import MainHeader from "../../Component/MainHeader/MainHeader";
import "./KycPage.css";
import { Button, Image } from "react-bootstrap";
import { ongetSetting } from "../EarningPage/action";
import { Link } from "react-router-dom";

function AadharKYC() {
    const [toggleMenu, setToggleMenu] = useState(false);
    const [Setting, setSetting] = useState({});

    const handleClick = () => {
        setToggleMenu(!toggleMenu);
    };
    useEffect(() => {
        getStatus();
    }, [0]);
    const getStatus = () => {
        ongetSetting().then((response) => {
            if (response?.data?.IsSuccess) {
                setSetting(response.data.Data);
            }
        });
    };
    return (
        <>
            <div className={`MainScreen  ${toggleMenu ? "show" : ""}`}>
                <MainHeader handleClick={handleClick} />
                <hr />
                <Sidebar />
                <div className='AadharKYC'>
                    <h4>KYC Verification</h4>
                    <small>You need to submit a document that shows that you are above 18 years of age and not a resident of Assam, Odisha, Sikkim, Nagaland, Telangana, Andhra Pradesh, Tamil Nadu and Karnataka.</small>
                    {Setting.aadhar_verification === 1 ?
                        <Link to="/Kyc" className="btn btn-green">  <Image src="/image/img_online.png" />Onilne Aadhar KYC</Link>
                        : Setting.aadhar_verification == 3 ?
                            (<div><Link to="/Kyc" className="btn btn-green">  <Image src="/image/img_online.png" />Onilne Aadhar KYC</Link>
                                <Link to="/Kyc" className="btn btn-green">  <Image src="/image/img_offline.png" />Offline Aadhar KYC</Link>
                            </div>)
                            : <Link to="/Kyc" className="btn btn-green">  <Image src="/image/img_offline.png" />Offline Aadhar KYC</Link>}
                </div>
            </div>
        </>
    );
}

export default AadharKYC;
