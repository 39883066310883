import Axios from "../../AxiosApi";

export const onCheckStatus = () => {
  return Axios({
    method: "get",
    url: `profile/check-pancard-status`,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

export const ongetSetting = () => {
    return Axios({
      method: "get",
      url: `setting`,
    })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
  };

export const onStorePancard = (panData) => {
    return Axios({
      method: "post",
      url: `profile/store-pancard`,
      data: panData
    })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
  };

  export const onStoreRedeem = (redeemData) => {
    return Axios({
      method: "post",
      url: `referral/redeem`,
      data: redeemData
    })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err.response;
      });
  };
