import { Button, Col, Form, Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import Sidebar from '../../Component/Sidebar/Sidebar';
import MainHeader from '../../Component/MainHeader/MainHeader';
import './ReferEarnPage.css';
import { getReferralview } from '../ReferralHistory/action';

function ReferEarnPage() {
  const [toggleMenu, setToggleMenu] = useState(false)
  const [getRefer, setgetRefer] = useState(false)
  const handleClick = () => {
    setToggleMenu(!toggleMenu)
  }

  useEffect(() => {
    getReferral();
  }, [0]);

  const getReferral = () => {
    getReferralview().then((response) => {
      if (response?.data?.IsSuccess) {
        setgetRefer(response.data);
      }
    });
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(`Hi Friends,\nPlay Ludo and earn 10000 daily\nDownload 🇮🇳 Indian App RK Ludo\n\nRk Ludo  पर लूडो खेल कर या दोस्तों को शेयर कर के  पैसा जीतो और तुरंत अपने Bank या UPI में ट्रांसफर कर सकते है।\n\n100% भरोसेमंद प्लेटफार्म। 24 hours support\nhttps://rkludo.com/refer/${getRefer.referral_code}
    \nYour Referral code is =  ${getRefer.referral_code}`)
  };
  return (
    <>
      <div className={`MainScreen  ${toggleMenu ? 'show' : ''}`}>
        <MainHeader handleClick={handleClick} />
        <hr />
        <Sidebar />
        <div className='ReferBody'>
          <Image className='mx-50' src='/image/refer.png' />
          <div className='earnInfo'>
            <h3>Earn now unlimited</h3>
            <p>Refer your friends now!</p>
            <h6>Current Earning : ₹{getRefer.referral_balance}<Link to="/earning" className="text-danger ps-1">Redeem</Link></h6>
            <h6>Total Earned: ₹{getRefer.total_referral}</h6>
            <h5>Your Refer Code : {getRefer.referral_code}</h5>
            <h6>Total Refers: <strong>{getRefer.referral_count}</strong></h6>
          </div>
          <hr />
          <div className='referrules'>
            <h4>Refer & Earn Rules</h4>
            <ul>
              <li>
                <span><Image src='/image/refer-01.png' /></span>
                <h6>When your friend signs up on RkLudo from your referral link, <i>You get {getRefer.referral_percentage}% Commission on your referral's winnings.</i></h6>
              </li>
              <li>
                <span><Image src='/image/refer-02.png' /></span>
                <h6>Suppose your referral plays a battle for ₹10000 Cash, <i>You get ₹100 Cash</i></h6>
              </li>
            </ul>
          </div>
          <hr />
          <h6>SHARE IN LISTED CHANNELS:</h6>
          <div className='BtnWrap'>
            {/* https://api.whatsapp.com/send?phone=%22919587339757%22&%22&text=http://dating.prayosha.info/login?referral_code=${getRefer.referral_code} */}
            <a href={`https://api.whatsapp.com/send?text=Hi Friends,%0APlay Ludo and earn 10000 daily%0ADownload 🇮🇳 Indian App RK Ludo%0A%0ARk Ludo  पर लूडो खेल कर या दोस्तों को शेयर कर के  पैसा जीतो और तुरंत अपने Bank या UPI में ट्रांसफर कर सकते है।%0A%0A100% भरोसेमंद प्लेटफार्म। 24 hours support%0Ahttps://rkludo.com/refer/${getRefer.referral_code}
    %0AYour Referral code is =  ${getRefer.referral_code}`} className='btn Btn-White'><Icon icon="logos:whatsapp-icon" /> WhatsApp</a>
            <a href='https://t.me/Rkserviceprovider' className='btn Btn-White'><Icon icon="logos:telegram" /> Telegram</a>
            <a href='#' className='btn Btn-White'><Icon icon="logos:twitter" /> Twitter</a>
            <a onClick={handleCopy} className='btn Btn-White'><Icon icon="material-symbols:content-copy" color="#60a8ff" /> Copy Code</a>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReferEarnPage;
