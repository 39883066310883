import { Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import Sidebar from '../../Component/Sidebar/Sidebar';
import MainHeader from '../../Component/MainHeader/MainHeader';
import './WalletPage.css';
import { onCheckUserKyc, onGetUserProfile } from '../ProfilePage/action';
import { toast } from 'react-toastify';

function WalletPage() {
  const [toggleMenu, setToggleMenu] = useState(false)
  const [userInfo, setUserInfo] = useState({});
  const [userviewInfo, setUserviewInfo] = useState({});
  const navigate = useNavigate();

  const handleClick = () => {
    setToggleMenu(!toggleMenu)
  }
  useEffect(() => {
    checkUserKyc();
    getUserProfile();
  }, [0]);

  const checkUserKyc = () => {
    onCheckUserKyc().then((response) => {
      if (response?.data?.IsSuccess) {
        setUserInfo(response.data);
      }
    });
  };
  const getUserProfile = () => {
    onGetUserProfile().then((response) => {
      if (response?.data?.IsSuccess) {
        setUserviewInfo(response.data);
      }
    });
  };
  const onWithdraw = () => {
    // console.log({ userInfo })

    if (userInfo.is_verified === false) {
      if(userInfo.is_uploaded === 0) {
        toast.warn("Please Complete Your KYC First.")
        setTimeout(() => {
          navigate('/profile');
        }, 1000);
      } else if(userInfo.is_uploaded === 1) {
        toast.warn("Your KYC is in pending.Please wait for Admin Approval.")
      } else if(userInfo.is_uploaded === 3) {
        toast.warn("Your KYC is Rejected, Please Re-Upload your Document")
      }
    }
    else if(!userviewInfo.email){
      toast.error("Please Enter The Profile Email.")
      setTimeout(() => {
        navigate('/profile');
      }, 2500);
    }
    else {
      if(userInfo.is_suspend === 0){
        navigate('/withdraw');   
      }else{
        toast.error("Your KYC Is Suspended. Please contact to The support Team.")
      }
    }
  };
  return (
    <>
      <div className={`MainScreen  ${toggleMenu ? 'show' : ''}`}>
        <MainHeader handleClick={handleClick} />
        <hr />
        <Sidebar />
        <div className='WalletBody'>
          <Link className='HistoryBox btn btn-white' to="/transactions"><Icon icon="ic:outline-history" /> Order History</Link>
          <div className='WalletCard BlueCard'>
            <div className='WalletCardHeader'>
              <h6>₹ {userviewInfo.wallet_balance}  <span>DEPOSIT CASH</span></h6>
              <Link to="/addcash" className='btn btn-white'>ADD CASH</Link>
            </div>
            <p>Can be used to play Tournaments & Battles. Cannot be withdrawn to Paytm or Bank.</p>
          </div>
          <div className='WalletCard LightBlueCard'>
            <div className='WalletCardHeader'>
              <h6>₹ {userviewInfo.withdraw_balance} <span>WINNING CASH</span></h6>
              <Button onClick={onWithdraw} className='btn btn-white'>WITHDRAW</Button>
            </div>
            <p>Can be withdrawn to Paytm or Bank. Can be used to play Tournaments & Battles.</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default WalletPage;
