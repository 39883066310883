import { Image, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import Sidebar from "../../Component/Sidebar/Sidebar";
import MainHeader from "../../Component/MainHeader/MainHeader";
import "./../TransactionsPage/TransactionsPage.css";
import moment from "moment";
import { onWithdrawRequest } from "./action";
import { toast } from "react-toastify";

function WithdrawRequest() {
    const [toggleMenu, setToggleMenu] = useState(false);
    const [gamelist, setGamelist] = useState([]);
    const initLimit = "20";
    const [page, setPage] = useState("1");
    const [totalPage, setTotalPage] = useState(0);
    const handleClick = () => {
        setToggleMenu(!toggleMenu);
    };
    const [eyeClick, setEyeClick] = useState(false);
    const [eyeClick1, setEyeClick1] = useState(false);
    const [eyeUPIClick, setEyeUPIClick] = useState(false);
    const [selectedGameData, setSelectedGameData] = useState({});
    const handleeyeClick = (gameData) => {
        setSelectedGameData(gameData)
        setEyeClick(!eyeClick);
    };
    const handleUPIeyeClick = (gameData) => {
        setSelectedGameData(gameData)
        setEyeUPIClick(!eyeUPIClick);
    };
    const handleeyeClick1 = (gameData) => {
        setSelectedGameData(gameData)
        setEyeClick1(!eyeClick1);
    };
    useEffect(() => {
        const data = {
            limit: initLimit,
            page_number: page,
        };
        getAllTransactionList(data);
    
    }, [0]);

    const getAllTransactionList = (data) => {
        onWithdrawRequest(data).then((resp) => {
            if (resp.data.IsSuccess) {
                const totalPage = resp.data.totalRecords / Number(data.limit);
                if (totalPage < 1) {
                    setTotalPage(1);
                } else {
                    setTotalPage(Math.ceil(totalPage));
                }
                setGamelist(resp.data.Data);
            }

        });
    };

    const onChangePage = (pageNumber) => {
        if (page !== pageNumber.toString()) {
            setPage(pageNumber.toString());
            const data = {
                limit: initLimit,
                page_number: pageNumber.toString(),
            };
            getAllTransactionList(data);
        }
    };

    const showPagination = () => {
        let items = [];
        for (let number = 1; number <= totalPage; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === Number(page)}
                    onClick={() => onChangePage(number)}
                >
                    {number}
                </Pagination.Item>
            );
        }
        return items;
    };

    return (
        <>
            <div className={`MainScreen  ${toggleMenu ? "show" : ""}`}>
                <MainHeader handleClick={handleClick} />
                <hr />
                <Sidebar />
                {gamelist?.length > 0 ? (
                    <div className="transactionsBody">
                        <Pagination size="sm">{showPagination()}</Pagination>

                        {/* <Image src='/image/transactions.png' />
          <h6>No transactions yet!</h6>
          <p>Seems like you haven't done any activity yet</p> */}
                        {gamelist?.map((game, index) => (
                            <div key={index}>
                                <div className="GameTransctionItem">
                                    <div className="GameTransctionTime">
                                        <h6>{moment(game.created_date_time).format("MMM Do")}</h6>
                                        <small>{moment(game.created_date_time).format("LT")}</small>
                                    </div>
                                    <div className="GameTransctionInfo">
                                        {
                                            (() => {
                                                if (game.payment_method === "Bank") {
                                                    return (<>
                                                        <Link onClick={() => handleeyeClick(game)} to="#">
                                                            <Icon icon="ic:baseline-remove-red-eye" />
                                                        </Link>
                                                    </>)
                                                } else if (game.payment_method === "UPI") {
                                                    return (<>
                                                        <Link onClick={() => handleUPIeyeClick(game)} to="#">
                                                            <Icon icon="ic:baseline-remove-red-eye" />
                                                        </Link>
                                                    </>)
                                                }
                                            })()
                                        }
                                        <h6>

                                            Cash withdrawen By {game.payment_method}

                                            <small>{game.transfer_id ? game.transfer_id : game.reference_id}</small>
                                            <small>Status: {game.status_name}
                                            </small>
                                        </h6>
                                    </div>
                                    <div className="GametransctionCash">
                                        <p>
                                            <Icon icon="fluent-emoji:money-with-wings" />{" "}
                                            {game.amount}
                                        </p>
                                    </div>
                                </div>


                                {game.payment_method === "Bank" ? (
                                    <div div className={`Popupp  ${eyeClick ? "show" : ""}`}>
                                        <h3>
                                            Withdrawl Details
                                            <Link to="#" onClick={() => handleeyeClick({})}>
                                                <Icon icon="material-symbols:close" />
                                            </Link>
                                        </h3>
                                        <ul>
                                            <li>
                                                <strong>Order ID</strong>{selectedGameData.transfer_id ? selectedGameData.transfer_id : selectedGameData.reference_id}
                                            </li>
                                            <li>
                                                <strong>Request Time</strong>{moment(selectedGameData.created_at).format("lll")}
                                            </li>
                                            <li>
                                                <strong>Processed Time</strong>{moment(selectedGameData.updated_at).format("lll")}
                                            </li>
                                            <li>
                                                <strong>Status</strong>
                                                {
                                                    (() => {
                                                        if (selectedGameData.status_name === "Accepted") {
                                                            return (
                                                                <i className="greenTag">{selectedGameData.status_name} </i>
                                                            )
                                                        } else if (selectedGameData.status_name === "Rejected") {
                                                            return (
                                                                <i className="RedTag">{selectedGameData.status_name} </i>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <i className="YellowTag">{selectedGameData.status_name}</i>
                                                            )
                                                        }
                                                    })()
                                                }

                                            </li>
                                            <li>
                                                <strong>Payment Details</strong>
                                            </li>
                                            <li className="Border-1">
                                                <div><strong>Account Number: {selectedGameData.account_number}</strong>
                                                    <li className="mb-0">
                                                        <strong>IFSC Code: {selectedGameData.ifsc_code}</strong></li></div>
                                            </li>
                                        </ul>
                                        <p className="text-dark">
                                            नोट:- यदि आपका Withdrawl स्टेटस 'Pending' दिखा रहा है तो
                                            वो बैंक की तरफ़ से Pending है हमारे तरफ़ से भेज दिया गया
                                            है। इसलिये कृपया 1-5 दिन का इंतज़ार करे । पेंडिंग हटने के
                                            बाद भी यदि आपके पैसे नहीं आते है तो Support पेज में से
                                            हमें संपर्क करें ।
                                        </p>
                                    </div>) : ""
                                }

                                {game.payment_method === "UPI" ? (
                                    <div div className={`Popupp  ${eyeUPIClick ? "show" : ""}`}>
                                        <h3>
                                            Withdrawl Details
                                            <Link to="#" onClick={() => handleUPIeyeClick({})}>
                                                <Icon icon="material-symbols:close" />
                                            </Link>
                                        </h3>
                                        <ul>
                                            <li>
                                                <strong>Order ID</strong>{selectedGameData.transfer_id ? selectedGameData.transfer_id : selectedGameData.reference_id}
                                            </li>
                                            <li>
                                                <strong>Request Time</strong>{moment(selectedGameData.created_at).format("lll")}
                                            </li>
                                            <li>
                                                <strong>Processed Time</strong>{moment(selectedGameData.updated_at).format("lll")}
                                            </li>
                                            <li>
                                                <strong>Status</strong>
                                                {
                                                    (() => {
                                                        if (selectedGameData.status_name === "Accepted") {
                                                            return (
                                                                <i className="greenTag">{selectedGameData.status_name} </i>
                                                            )
                                                        } else if (selectedGameData.status_name === "Rejected") {
                                                            return (
                                                                <i className="RedTag">{selectedGameData.status_name} </i>
                                                            )
                                                        }
                                                        else {
                                                            return (
                                                                <i className="YellowTag">{selectedGameData.status_name}</i>
                                                            )
                                                        }
                                                    })()
                                                }

                                            </li>
                                            <li>
                                                <strong>Payment Details</strong>
                                            </li>
                                            <li className="Border-1">
                                                <strong>UPI ID: {selectedGameData.upi}</strong>

                                            </li>
                                        </ul>
                                        <p className="text-dark">
                                            नोट:- यदि आपका Withdrawl स्टेटस 'Pending' दिखा रहा है तो
                                            वो बैंक की तरफ़ से Pending है हमारे तरफ़ से भेज दिया गया
                                            है। इसलिये कृपया 1-5 दिन का इंतज़ार करे । पेंडिंग हटने के
                                            बाद भी यदि आपके पैसे नहीं आते है तो Support पेज में से
                                            हमें संपर्क करें ।
                                        </p>
                                    </div>) : ""}

                            </div>
                        ))}
                    </div>
                ) : (
                    <div className="NotificationPageBody">
                        <Image src="/image/transactions.png" />
                        <h6 className="text-white">No transactions yet!</h6>
                        <p className="text-white">
                            Seems like you haven't done any activity yet
                        </p>
                    </div>
                )}



            </div>
        </>
    );
}

export default WithdrawRequest;
