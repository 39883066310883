import Axios from "../../AxiosApi";

export const onGetAllState = (loginData) => {
  return Axios({
    method: "get",
    url: `states`,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const onCompleteUserKyc = (kycData) => {
  return Axios({
    method: "post",
    url: `profile/offline-kyc`,
    data: kycData,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

