import { Button, Form } from 'react-bootstrap';
import './WizardStep1.css';

const WizardStep1 = (props) => {

  return (
    <>
     <div className='KycFrom'>
        <h4>KYC Verification</h4>
        <p>Step 1 of 3</p>
        <small>You need to submit a document that shows that you are above 18 years of age and not a resident of Assam, Odisha, Sikkim, Nagaland, Telangana, Andhra Pradesh, Tamil Nadu and Karnataka.</small>
        <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Document type</Form.Label>
              <Form.Control type="text" value="Aadhar Card" />
            </Form.Group> 
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label> Aadhaar Card Number</Form.Label>
              <Form.Control type="number" name='aadhar_number' placeholder="" onChange={props.onHandleChange}/>
              {props.aadharNumError && <Form.Label>Must be 12 characters</Form.Label>}
            </Form.Group> 
            {/* <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>OTP</Form.Label>
              <Form.Control type="number" placeholder="" />
            </Form.Group>  */}
        </Form>
     </div>

     {/* {console.log({ dada:  props?.kycData?.aadhar_number === '',  check: props?.kycData?.aadhar_number === '' || props.aadharNumError })} */}
     <Button variant='' className='btn btn-green btn-block w-100 mt-5' onClick={props.nextStep} disabled={props?.kycData?.aadhar_number === '' || props.aadharNumError}>Next</Button>
    </>
  );
}

export default WizardStep1;
