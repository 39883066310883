import Axios from "../../AxiosApi";

export const onGetAllRunningGames = (game) => {
  return Axios({
    method: "post",
    url: `games/running`,
    data: game
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const onGetAllOpenBattles = (game) => {
  return Axios({
    method: "post",
    url: `games/open-battles`,
    data: game
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const onAddNewBettle = (game) => {
  return Axios({
    method: "post",
    url: `game/add-request`,
    data: game
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const onCheckBettleRequest = (game) => {
  return Axios({
    method: "post",
    url: `game/check-request`,
    data: game
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const onCancelGameStarted  = (game) => {
  return Axios({
    method: "post",
    url: `game/cancel-game`,
    data: game
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};
export const onStartTheGame  = (game) => {
  return Axios({
    method: "post",
    url: `game/start-game`,
    data: game
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};